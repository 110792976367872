import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "./DataTableColumnHeader";

export const columns: ColumnDef<any>[] = [
  {
    accessorKey: "dataPageses",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Data" />
    ),
    // cell: ({ row }) => <div className="w-[80px]">{row.getValue("id")}</div>,
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "monedha",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Monedha" />
    ),
    // cell: ({ row }) => {
    //   const label = labels.find((label) => label.value === row.original.label);

    //   return (
    //     <div className="flex space-x-2">
    //       {label && <Badge variant="outline">{label.label}</Badge>}
    //       <span className="max-w-[500px] truncate font-medium">
    //         {row.getValue("title")}
    //       </span>
    //     </div>
    //   );
    // }
  },
  {
    accessorKey: "vleraPageses",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Kesti" />
    ),
    // cell: ({ row }) => {
    //   const label = labels.find((label) => label.value === row.original.label);

    //   return (
    //     <div className="flex space-x-2">
    //       {label && <Badge variant="outline">{label.label}</Badge>}
    //       <span className="max-w-[500px] truncate font-medium">
    //         {row.getValue("title")}
    //       </span>
    //     </div>
    //   );
    // },
  },
  {
    accessorKey: "vleraInteresit",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Interesi vjetor" />
    ),
    // cell: ({ row }) => {
    //   const label = labels.find((label) => label.value === row.original.label);

    //   return (
    //     <div className="flex space-x-2">
    //       {label && <Badge variant="outline">{label.label}</Badge>}
    //       <span className="max-w-[500px] truncate font-medium">
    //         {row.getValue("title")}
    //       </span>
    //     </div>
    //   );
    // },
  },
  {
    accessorKey: "kamatvonese",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Kamatvonese" />
    ),
    // cell: ({ row }) => {
    //   const label = labels.find((label) => label.value === row.original.label);

    //   return (
    //     <div className="flex space-x-2">
    //       {label && <Badge variant="outline">{label.label}</Badge>}
    //       <span className="max-w-[500px] truncate font-medium">
    //         {row.getValue("title")}
    //       </span>
    //     </div>
    //   );
    // },
  },
  {
    accessorKey: "vleraMbetur",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Vlera e mbetur e detyrimit"
      />
    ),
  },
  {
    accessorKey: "pershkrimi",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Pershkrimi" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    cell: ({ row }) => {
      return `Kesti ${row?.original?.numriPageses}`;
    },
  },
  {
    id: "actions",
    // cell: ({ row }) => <DataTableRowActions row={row} />,
  },
];
