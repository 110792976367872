import { useMutation } from "@tanstack/react-query";
import { Check } from "lucide-react";
import { useEffect, useState } from "react";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { cn } from "src/lib/utils";
import {
  caktoSpecialistRast,
  getRasteListing,
  getSpecialistetListing,
} from "../../api";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import { Spinner } from "../ui/spinner";
import { useToast } from "../ui/use-toast";

interface Specialist {
  emri: string;
  id: number;
  numriRasteve: number;
}

interface Raste {
  id: number;
  name: string;
}

const Specialiste = () => {
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");
  const [specialistet, setSpecialistet] = useState<Specialist[]>([]);
  const [open, setOpen] = useState(false);
  const [raste, setRaste] = useState<Raste[]>([]);
  const [rastDetails, setRastDetails] = useState<Raste>();
  const [specialistDetails, setSpecialistDetails] = useState<Specialist>();
  const [value, setValue] = useState("");
  const { toast } = useToast();

  const specialistetMutation = useMutation({
    mutationFn: getSpecialistetListing,
    onSuccess: (data) => {
      setSpecialistet(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    specialistetMutation.mutate({
      token,
    });
  }, []);

  const rasteMutation = useMutation({
    mutationFn: getRasteListing,
    onSuccess: (data) => {
      setRaste(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const caktoSpecialistMutation = useMutation({
    mutationFn: caktoSpecialistRast,
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Success",
        description: "Rasti u caktua me sukses",
      });
      specialistetMutation.mutate({
        token,
      });
      setOpen(false);
    },
    onError: (error) => {
      console.log(error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: error.message,
      });
    },
  });

  useEffect(() => {
    rasteMutation.mutate({
      token,
    });
  }, [open]);

  const onOpenChange = () => {
    setOpen(!open);
    setValue("");
  };

  const handleSelectionChange = () => {
    caktoSpecialistMutation.mutate({
      token,
      personId: specialistDetails ? specialistDetails.id : 0,
      rastId: rastDetails ? rastDetails.id : 0,
    });
  };

  if (specialistetMutation?.isPending) {
    return (
      <div className="px-5 w-1/2 justify-center mt-5">
        <Card>
          <CardHeader className="flex items-center justify-center">
            <CardTitle>Loading...</CardTitle>
          </CardHeader>
          <CardFooter className="flex items-center justify-center">
            <Spinner fill="black" />
          </CardFooter>
        </Card>
      </div>
    );
  }

  return (
    <div className="w-full px-5">
      <div className=" grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {specialistet.length > 0 &&
          specialistet.map((specialist: Specialist) => (
            <Card key={specialist.id}>
              <CardHeader>
                <CardTitle>{specialist.emri}</CardTitle>
              </CardHeader>
              <CardContent />
              <CardFooter className="flex flex-row sm:flex-row justify-between  items-center sm:space-x-4 space-y-2 sm:space-y-0">
                <span className="text-center sm:text-left">
                  {`Numri i rasteve ${specialist.numriRasteve}`}
                </span>
                <Dialog
                  open={open}
                  onOpenChange={() => {
                    onOpenChange();
                    setSpecialistDetails(
                      specialistet.find((s) => s.id === specialist.id)
                    );
                  }}
                >
                  <DialogTrigger asChild>
                    <Button variant="default" className="items-center h-auto">
                      Cakto nje rast
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-[425px]">
                    <Command>
                      <CommandInput placeholder="Kerko nje rast..." />
                      <CommandList>
                        {raste.length > 0 &&
                          raste.map((rast) => (
                            <CommandItem
                              key={rast.id}
                              value={rast.name}
                              onSelect={(currentValue) => {
                                setValue(
                                  currentValue === value ? "" : currentValue
                                );
                                setRastDetails(
                                  currentValue === value
                                    ? undefined
                                    : raste.find((r) => r.id === rast.id)
                                );
                              }}
                            >
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  value === rast.name
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              {rast.name}
                            </CommandItem>
                          ))}

                        <CommandEmpty>No results found.</CommandEmpty>
                      </CommandList>
                      <Button
                        onClick={handleSelectionChange}
                        className="mt-4 justify-center items-center"
                        variant="default"
                      >
                        {caktoSpecialistMutation?.isPending ? (
                          <Spinner fill="black" />
                        ) : (
                          "Cakto"
                        )}
                      </Button>
                    </Command>
                  </DialogContent>
                </Dialog>
              </CardFooter>
            </Card>
          ))}
      </div>
    </div>
  );
};

export default Specialiste;
