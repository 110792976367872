import { useMutation } from "@tanstack/react-query";
import { Table } from "@tanstack/react-table";
import { format } from "date-fns";
import { Download } from "lucide-react";
import { DateRange } from "react-day-picker";
import { Specialist } from "src/components/types/tabela-portofol";
import { Button } from "src/components/ui/button";
import { Spinner } from "src/components/ui/spinner";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import {
  downloadKlasifikimKredie,
  downloadRaportArketime,
  downloadRaportArketueshme,
  downloadRaportEvidencaKreditit,
  downloadRaportMaturim,
  downloadRaportPastDue,
  downloadRaportPermbarues,
  downloadRaportSecured,
  downloadRaportSipasSegmentit,
  downloadRaportSituateLigjore,
} from "../../../api";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  specialist: Specialist | undefined;
  date: DateRange | undefined;
  value: string;
  permbarues: any;
}

export function DataTableToolbar<TData>({
  table,
  specialist,
  date,
  value,
  permbarues,
}: DataTableToolbarProps<TData>) {
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");

  const downloadReportData = (reportType: string) => {
    switch (reportType) {
      case "arketime":
        return downloadRaportArketime;
      case "klasifikim-kredie":
        return downloadKlasifikimKredie;
      case "evidenca-kreditit":
        return downloadRaportEvidencaKreditit;
      case "past-due":
        return downloadRaportPastDue;
      case "maturim":
        return downloadRaportMaturim;
      case "te-arketueshme":
        return downloadRaportArketueshme;
      case "situate-ligjore":
        return downloadRaportSituateLigjore;
      case "secured-unsecured":
        return downloadRaportSecured;
      case "raport-permbarues":
        return downloadRaportPermbarues;
      case "sipas-segmentit":
        return downloadRaportSipasSegmentit;
      default:
        return downloadRaportArketime;
    }
  };

  const downloadFileMutation = useMutation({
    mutationFn: (body: any) => downloadReportData(value)(body),
    onSuccess: (response) => {
      const disposition = response.headers["content-disposition"];
      const contentType = response.headers["content-type"];
      let filename = "downloaded-file";

      if (disposition && disposition.includes("attachment")) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }
      const mimeType = contentType || "application/octet-stream";

      const blob = new Blob([response.data], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
    onError: (error) => {
      console.error("Error downloading file:", error);
    },
  });

  const handleDownload = () => {
    const body = {
      specialistId:
        value === "raport-permbarues"
          ? permbarues?.id || 0
          : specialist?.id || 0,
      from: date?.from ? format(date.from, "yyyy-MM-dd") : "",
      to: date?.to ? format(date.to, "yyyy-MM-dd") : "",
      token,
    };
    downloadFileMutation.mutate(body);
  };

  return (
    table.getRowCount() > 0 && (
      <div className="flex items-center justify-end mr-3">
        <Button variant={"outline"} size={"icon"} onClick={handleDownload}>
          {downloadFileMutation.isPending ? (
            <Spinner fill="black" />
          ) : (
            <Download />
          )}
        </Button>
      </div>
    )
  );
}
