import { Row } from "@tanstack/react-table";

import { zodResolver } from "@hookform/resolvers/zod";
import { Check, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Dialog, DialogContent, DialogTrigger } from "src/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import { Spinner } from "src/components/ui/spinner";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/ui/tooltip";
import { z } from "zod";
import { Button } from "../../../ui/button";
interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  handlePay: (id: number, kursi: number) => void;
  paguajKestMutation: any;
}

const formSchema = z.object({
  kursi: z
    .string()
    .min(1, { message: "Kursi i kembimit nuk duhet të jetë bosh" }),
});

export function DataTableRowActions<TData>({
  row,
  handlePay,
  paguajKestMutation,
}: DataTableRowActionsProps<TData>) {
  const [rowId, setRowId] = useState<number | null>(null);
  const renderButtonContent = () => {
    if (paguajKestMutation?.isPending && (row.original as any).id === rowId) {
      return <Spinner fill="black" />;
    }
    if ((row.original as any).paguar) {
      return <Check className="text-success" />;
    }
    return <X className="text-danger" />;
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      kursi: "1",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    handlePay((row.original as any).id, parseFloat(values.kursi));
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div>
            {(row.original as any).monedha === "LEK" ? (
              <Button
                onClick={() => {
                  handlePay((row.original as any).id, 1);
                  setRowId((row.original as any).id);
                }}
                disabled={(row.original as any).paguar}
                variant="outline"
                className={`py-2 my-2 ${
                  (row.original as any).paguar
                    ? "text-success border-success"
                    : "text-danger border-danger"
                }`}
              >
                {renderButtonContent()}
              </Button>
            ) : (
              <Dialog>
                <DialogTrigger asChild>
                  <Button
                    onClick={() => {
                      setRowId((row.original as any).id);
                    }}
                    disabled={(row.original as any).paguar}
                    variant="outline"
                    className={`py-2 my-2 ${
                      (row.original as any).paguar
                        ? "text-success border-success"
                        : "text-danger border-danger"
                    }`}
                  >
                    {renderButtonContent()}
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <Form {...form}>
                    <form
                      onSubmit={form.handleSubmit(onSubmit)}
                      className="grid grid-cols-1 gap-4"
                    >
                      <FormField
                        control={form.control}
                        name="kursi"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Kursi Kembimit</FormLabel>
                            <FormControl>
                              <Input placeholder="Kursi Kembimit" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <Button type="submit">
                        {paguajKestMutation?.isPending ? (
                          <Spinner fill="black" />
                        ) : (
                          "Paguaj Kestin"
                        )}
                      </Button>
                    </form>
                  </Form>
                </DialogContent>
              </Dialog>
            )}
          </div>
        </TooltipTrigger>
        <TooltipContent
          className={`${
            (row.original as any).paguar
              ? "bg-success border-success text-white"
              : "text-white bg-danger border-danger"
          }`}
        >
          {(row.original as any).paguar ? "Paguar" : "Papaguar"}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
