import { Separator } from "@radix-ui/react-separator";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { getDebitorDetails } from "../../api";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Spinner } from "../ui/spinner";

const Dorezanes = () => {
  let { debitorId } = useParams();
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");
  const [debitorData, setDebitorData] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    if (debitorId) {
      const body = {
        token,
        debitorId,
      };
      debitorDataMutation.mutate(body);
    }
  }, [debitorId, token]);

  const debitorDataMutation = useMutation({
    mutationFn: getDebitorDetails,
    onSuccess: (data) => {
      setDebitorData(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  if (debitorDataMutation.isPending) {
    return (
      <div className="px-5 w-1/2 justify-center mt-5">
        <Card>
          <CardHeader className="flex items-center justify-center">
            <CardTitle>Loading...</CardTitle>
          </CardHeader>
          <CardFooter className="flex items-center justify-center">
            <Spinner fill="black" />
          </CardFooter>
        </Card>
      </div>
    );
  }

  return (
    <div className="h-screen w-full relative px-5">
      <div className="h-screen w-full relative px-5">
        <Card>
          <CardHeader className="justify-start flex items-start">
            <CardTitle className="text-sm">
              {debitorData?.emri} -
              <span className="text-secondary">{debitorData?.status}</span>
            </CardTitle>
          </CardHeader>
        </Card>
        <div className="mt-5 grid grid-cols-4 gap-3">
          <div className="col-span-1 sm:col-span-2 lg:col-span-2">
            <Card>
              <CardContent className=" p-3 m-3 grid flex-col gap-2">
                <div className="flex flex-row justify-between">
                  <span>KODI I RASTIT:</span>
                  <span>{debitorData?.id}</span>
                </div>
                <Separator />
                <div className="flex flex-row justify-between">
                  <span>NUIS:</span>
                  <span>{debitorData?.nipt}</span>
                </div>
                <Separator />
                <div className="flex flex-row justify-between items-center">
                  <span>ADMINISTRATORI:</span>
                  <Button
                    variant="link"
                    className="underline"
                    onClick={() =>
                      navigate(
                        `/portofol/rasti/klient/${debitorData?.administrator?.id}`
                      )
                    }
                  >{`${debitorData?.administrator?.emri} ${debitorData?.administrator?.mbiemer}`}</Button>
                </div>
                <Separator />
                <div className="flex flex-row justify-between items-center">
                  <span>ADRESA:</span>
                  <span>{debitorData?.administrator?.adrese?.street}</span>
                </div>
                <Separator />
                <div className="flex flex-row justify-between items-center">
                  <span>QYTETI:</span>
                  <span>{debitorData?.administrator?.adrese?.city}</span>
                </div>
                <Separator />
                <div className="flex flex-row justify-between">
                  <span>SHTETI:</span>
                  <span>{debitorData?.administrator?.adrese?.country}</span>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="col-span-1 sm:col-span-2 lg:col-span-2">
            <Card>
              <CardContent className=" p-3 m-3 flex flex-row gap-2 justify-evenly items-center">
                <div className="flex flex-col items-center">
                  <span>TELEFON QKB:</span>
                  <span>{debitorData?.telQKB}</span>
                </div>
                <Separator orientation="vertical" className="h-[100px]" />

                <div className="flex flex-col items-center">
                  <span>EMAIL QKB:</span>
                  <span>{debitorData?.emailQKB}</span>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dorezanes;
