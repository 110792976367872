import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import {
  aprovoKerkesen,
  getKerkesatPerAprovim,
  refuzoKerkesen,
} from "../../api";
import { Specialist } from "../types/tabela-portofol";
import { Avatar, AvatarImage } from "../ui/avatar";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Spinner } from "../ui/spinner";
import { useToast } from "../ui/use-toast";
import KerkesaAccordion from "./KerkesaAccordion";

export interface KerkesatPending {
  id: number;
  planPageseId: number;
  specialistId: number;
  statusAprovimi: string;
  koment: string;
  pershkrimi: string;
  specialist: Specialist;
}

const KerkesatAdmin = () => {
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");
  const [kerkesaData, setKerkesaData] = useState<KerkesatPending[]>([]);
  const { toast } = useToast();
  const [buttonClicked, setButtonClicked] = useState(0);

  const getKerkesatMutation = useMutation({
    mutationFn: getKerkesatPerAprovim,
    onSuccess: (data) => {
      setKerkesaData(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const aprovoKerkesenMutation = useMutation({
    mutationFn: aprovoKerkesen,
    onSuccess: (data) => {
      toast({
        variant: "success",
        title: "Success",
        description: "Kerkesa u aprovua me sukses",
      });
      getKerkesatMutation.mutate({
        token,
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const refuzoKerkesenMutation = useMutation({
    mutationFn: refuzoKerkesen,
    onSuccess: (data) => {
      toast({
        variant: "warning",
        title: "Informacion",
        description: "Kerkesa u refuzua me sukses",
      });
      getKerkesatMutation.mutate({
        token,
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    getKerkesatMutation.mutate({
      token: token,
    });
  }, []);

  if (getKerkesatMutation?.isPending) {
    return (
      <div className="px-5 w-1/2 justify-center mt-5">
        <Card>
          <CardHeader className="flex items-center justify-center">
            <CardTitle>Loading...</CardTitle>
          </CardHeader>
          <CardFooter className="flex items-center justify-center">
            <Spinner fill="black" />
          </CardFooter>
        </Card>
      </div>
    );
  }

  const handleAprovo = (id: number) => {
    aprovoKerkesenMutation.mutate({
      token,
      id,
      koment: "Aprovim sipas kerkeses",
    });
  };

  const handleRefuzo = (id: number) => {
    refuzoKerkesenMutation.mutate({
      token,
      id,
      koment: "Refuzim sipas kerkeses",
    });
  };

  return (
    <div className="h-screen relative px-5 w-full flex-row flex justify-between gap-4">
      <div
        className={`flex flex-col gap-4 ${kerkesaData.length > 0 ? "max-w-4xl w-10/12" : "w-full"
          }`}
      >
        {kerkesaData.length > 0 ? (
          kerkesaData.map((kerkesa) => (
            <Card key={kerkesa.id}>
              <CardHeader className="justify-start flex items-start p-3">
                <CardTitle className="text-sm flex flex-row justify-between items-center gap-3 w-full">
                  <div className=" flex flex-row items-center gap-2">
                    <Avatar>
                      <AvatarImage
                        src="https://github.com/shadcn.png"
                        alt="@shadcn"
                      />
                    </Avatar>
                    {`${kerkesa.specialist?.emri} ${kerkesa.specialist?.mbiemer}`}
                  </div>
                </CardTitle>
              </CardHeader>
              <CardContent>
                <Link
                  className="no-underline hover:underline decoration-secondary"
                  to={`/portofol/rasti/${kerkesa.id}`}
                  target="_blank"
                >
                  {kerkesa.pershkrimi}
                </Link>
              </CardContent>
              <CardFooter className="flex gap-3 p-2">
                <Button
                  variant="destructive"
                  onClick={() => {
                    handleRefuzo(kerkesa.id);
                    setButtonClicked(kerkesa.id);
                  }}
                >
                  {refuzoKerkesenMutation?.isPending &&
                    buttonClicked === kerkesa.id ? (
                    <Spinner fill="black" />
                  ) : (
                    "Refuzo"
                  )}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleAprovo(kerkesa.id);
                    setButtonClicked(kerkesa.id);
                  }}
                >
                  {aprovoKerkesenMutation?.isPending &&
                    buttonClicked === kerkesa.id ? (
                    <Spinner fill="black" />
                  ) : (
                    "Prano"
                  )}
                </Button>
              </CardFooter>
            </Card>
          ))
        ) : (
          <Card>
            <CardHeader className="flex items-center justify-center">
              <CardDescription>Nuk ka kerkesa</CardDescription>
            </CardHeader>
          </Card>
        )}
      </div>
      {kerkesaData.length > 0 && <KerkesaAccordion />}
    </div>
  );
};

export default KerkesatAdmin;
