// src/CommentComponent.tsx
import { format } from "date-fns";
import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Avatar, AvatarImage } from "../ui/avatar";
import { Button } from "../ui/button";
import { Comment } from "./KomentBox";

interface CommentComponentProps {
  comment: Comment;
  onReply: (id: number, text: string) => void;
}

const KomentComponent: React.FC<CommentComponentProps> = ({
  comment,
  onReply,
}) => {
  const [replyText, setReplyText] = useState<string>("");
  const [showReplyForm, setShowReplyForm] = useState<boolean>(false);
  const replyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    replyRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [comment]);

  const handleReplyChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setReplyText(e.target.value);
  };

  const handleReplySubmit = (e: FormEvent) => {
    e.preventDefault();
    if (replyText.trim()) {
      onReply(comment.id, replyText);
      setReplyText("");
      setShowReplyForm(false);
    }
  };

  return (
    <div className="mb-4 pb-4 border-b ml-4 bg-white p-3" ref={replyRef}>
      <div className="flex flex-row items-center gap-5">
        <Avatar>
          <AvatarImage src="https://github.com/shadcn.png" alt="@shadcn" />
        </Avatar>
        <div className="font-bold">{comment.person.username}</div>
        <div className="text-xs text-gray-500">
          {format(comment.data, "dd MMMM")}
        </div>
        {/* <Button
        className="flex flex-row w-auto gap-2"
          size="icon"
          variant="link"
          onClick={() => setShowReplyForm(!showReplyForm)}
        >
          <ReplyAll /> Reply
        </Button> */}
      </div>
      <div className="mt-2">{comment.komenti}</div>

      {showReplyForm && (
        <form className="mt-2" onSubmit={handleReplySubmit}>
          <textarea
            className="w-full p-2 border rounded-md mb-2"
            value={replyText}
            onChange={handleReplyChange}
            placeholder="Write your reply..."
          />
          <Button type="submit">Add Reply</Button>
        </form>
      )}

      {/* {comment.replies.map((reply) => (
        <KomentComponent key={reply.id} comment={reply} onReply={onReply} />
      ))} */}
    </div>
  );
};

export default KomentComponent;
