import { useKeycloak } from "@react-keycloak/web";
import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { isAdmin } from "src/lib/utils";
import { getDorezanes, getHipotekues } from "../../api";
import KomentBox from "../komente/KomentBox";
import { Raste } from "../types/tabela-portofol";
import { Button } from "../ui/button";
import { Card, CardContent } from "../ui/card";
import { Separator } from "../ui/separator";
import { Spinner } from "../ui/spinner";
import { Debitor } from "./Rasti";

interface Comment {
  author: string;
  text: string;
  date: string;
  replies: Comment[];
}

interface Hipotekues {
  id: number;
  emri: string;
  segmenti: string;
}

interface RastiCardsProps {
  rastDetailsData?: Raste;
  rastiDebitor?: Debitor;
}

const RastiCards = ({ rastDetailsData, rastiDebitor }: RastiCardsProps) => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  let { rastId } = useParams();
  const { getItem } = useLocalStorage("token");
  const filterData = getItem("filter");
  const token = getItem("token");

  const [comments, setComments] = useState<Comment[]>([]);
  const [commentText, setCommentText] = useState<string>("");
  const [hipotekues, setHipotekues] = useState<Hipotekues>();
  const [dorezanes, setDorezanes] = useState<Hipotekues>();

  const getHipotekuesMutation = useMutation({
    mutationFn: getHipotekues,
    onSuccess: (data) => {
      setHipotekues(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const getDorezanesMutation = useMutation({
    mutationFn: getDorezanes,
    onSuccess: (data) => {
      setDorezanes(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (token && rastId) {
      const body = { token, rastId };
      getHipotekuesMutation.mutate(body);
      getDorezanesMutation.mutate(body);
    }
  }, [token, rastId]);

  const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCommentText(e.target.value);
  };

  const handleCommentSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (commentText.trim()) {
      setComments([
        ...comments,
        {
          author: "User",
          text: commentText,
          date: new Date().toLocaleString(),
          replies: [],
        },
      ]);
      setCommentText("");
    }
  };

  const handleReply = (index: number, text: string) => {
    const newComments = [...comments];
    newComments[index].replies.push({
      author: "User",
      text: text,
      date: new Date().toLocaleString(),
      replies: [],
    });
    setComments(newComments);
  };

  return (
    <div
      className={`grid grid-cols-1 sm:grid-cols-2 ${
        isAdmin(keycloak) ? "lg:grid-cols-3" : "lg:grid-cols-4"
      } w-full gap-1`}
    >
      <div
        className={`col-span-1 sm:col-span-2 ${
          isAdmin(keycloak) ? "lg:col-span-1" : "lg:col-span-2"
        }`}
      >
        <Card>
          <CardContent className="p-3 m-3 grid flex-col gap-2">
            <div className="flex flex-row justify-between items-center">
              <span className="w-1/2 whitespace-normal break-words">
                KODI I KLIENTIT:
              </span>
              <span className="w-1/2 text-right whitespace-normal break-words">
                {rastDetailsData?.kodiKlientit}
              </span>
            </div>
            <Separator />
            <div className="flex flex-row justify-between items-center">
              <span className="w-1/2 whitespace-normal break-words">
                EMËRTIMI I PORTOFOLIT:
              </span>
              <span className="w-1/2 text-right whitespace-normal break-words">
                {rastDetailsData?.portofoli}
              </span>
            </div>
            <Separator />
            <div className="flex flex-row justify-between items-center">
              <span className="w-1/2 whitespace-normal break-words">
                KLIENTI:
              </span>
              <Button
                variant="link"
                className="underline text-right"
                onClick={() =>
                  navigate(
                    `${
                      rastiDebitor?.segmenti === "Biznes"
                        ? `/portofol/rasti/biznes/${rastiDebitor?.id}`
                        : `/portofol/rasti/klient/${rastiDebitor?.id}`
                    }`,
                    { replace: true }
                  )
                }
              >
                {rastiDebitor?.emri}
              </Button>
            </div>
            <Separator />
            <div className="flex flex-row justify-between items-center">
              <span className="w-1/2 whitespace-normal break-words">
                DOREZANESI:
              </span>
              <Button
                variant="link"
                className="underline text-right"
                onClick={() =>
                  navigate(
                    `/portofol/rasti/subjekte-rasti/dorezanes/${rastId}`,
                    { replace: true }
                  )
                }
              >
                {getDorezanesMutation.isPending ? (
                  <Spinner fill="black" />
                ) : (
                  dorezanes?.emri
                )}
              </Button>
            </div>
            <Separator />
            <div className="flex flex-row justify-between items-center">
              <span className="w-1/2 whitespace-normal break-words">
                HIPOTEKUESI:
              </span>
              <Button
                variant="link"
                className="underline text-right"
                onClick={() =>
                  navigate(
                    `/portofol/rasti/subjekte-rasti/hipotekues/${rastId}`,
                    { replace: true }
                  )
                }
              >
                {getHipotekuesMutation.isPending ? (
                  <Spinner fill="black" />
                ) : (
                  hipotekues?.emri
                )}
              </Button>
            </div>
            <Separator />
            <div className="flex flex-row justify-between items-center">
              <span className="w-1/2 whitespace-normal break-words">
                SEGMENTI:
              </span>
              <span className="w-1/2 text-right whitespace-normal break-words">
                {filterData?.segmenti[rastDetailsData?.segmenti as any]}
              </span>
            </div>
            <Separator />
            <div className="flex flex-row justify-between items-center">
              <span className="w-1/2 whitespace-normal break-words">
                STATUSI:
              </span>
              <Button
                variant="outline"
                className={`w-1/2 text-right border-warning text-warning`}
              >
                {filterData?.statusVerbal[rastDetailsData?.statusVerbal as any]}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
      {isAdmin(keycloak) && (
        <div className="col-span-1 sm:col-span-2 lg:col-span-1">
          <Card>
            <CardContent className="p-3 m-3 grid flex-col gap-1">
              <div className="flex flex-row justify-between items-center">
                <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words">
                  Klasifikimi I kredive:
                </span>
                <span className="w-1/2 text-right whitespace-normal break-words">
                  {
                    filterData?.klasifikimKredie[
                      rastDetailsData?.klasifikimKredie as any
                    ]
                  }
                </span>
              </div>
              <Separator />
              <div className="flex flex-row justify-between items-center">
                <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words">
                  Evidenca e Kreditit Sipas Afatit te Maturimit:
                </span>
                <span className="w-1/2 text-right whitespace-normal break-words">
                  {
                    filterData?.ekSipasMaturimit[
                      rastDetailsData?.ekSipasMaturimit as any
                    ]
                  }
                </span>
              </div>
              <Separator />
              <div className="flex flex-row justify-between items-center">
                <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words">
                  Evidenca e Kreditit Sipas Degeve te Ekonomise:
                </span>
                <span className="w-1/2 text-right whitespace-normal break-words">
                  {
                    filterData?.evidencaKreditit[
                      rastDetailsData?.evidencaKreditit as any
                    ]
                  }
                </span>
              </div>
              <Separator />
              <div className="flex flex-row justify-between items-center">
                <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words">
                  Vlera e Provigjonit:
                </span>
                <span className="w-1/2 text-right whitespace-normal break-words">{`${rastDetailsData?.vleraProvigjonit}`}</span>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <div
        className={`col-span-1 sm:col-span-2 ${
          isAdmin(keycloak) ? "lg:col-span-1" : "lg:col-span-2"
        } flex-col flex gap-5`}
      >
        <Card>
          <CardContent className="p-3 m-3 grid flex-col gap-1">
            <div className="flex flex-row justify-between items-center">
              <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words">
                SITUATA LIGJORE:
              </span>
              <span className="w-1/2 text-right whitespace-normal break-words">
                {
                  filterData?.situateLigjore[
                    rastDetailsData?.situateLigjore as any
                  ]
                }
              </span>
            </div>
            <Separator />
            <div className="flex flex-row justify-between items-center">
              <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words">
                EMRI I ZYRËS PËRMBARIMORE:
              </span>
              <span className="w-1/2 text-right whitespace-normal break-words">
                {rastDetailsData?.zyraPermbarimore}
              </span>
            </div>
            <Separator />
            <div className="flex flex-row justify-between items-center">
              <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words">
                FAZA E PROCEDURES PËRMBARIMORE:
              </span>
              <span className="w-1/2 text-right whitespace-normal break-words">
                {
                  filterData?.fazaPermbarimit[
                    rastDetailsData?.fazaPermbarimit as any
                  ]
                }
              </span>
            </div>
            <Separator />
            <div className="flex flex-row justify-between items-center">
              <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words">
                SECURED/UNSECURED
              </span>
              <Button
                variant="outline"
                className={`w-1/2 text-right ${
                  rastDetailsData?.statusSigurie
                    ? "border-success text-success"
                    : "border-danger text-danger"
                }`}
              >
                {rastDetailsData?.statusSigurie === true
                  ? "Secured"
                  : "Unsecured"}
              </Button>
            </div>
          </CardContent>
        </Card>
        {rastId && <KomentBox rastId={rastId} />}
      </div>
    </div>
  );
};

export default RastiCards;
