import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import { Card, CardContent } from "../ui/card";

const KerkesaAccordion = () => {
  return (
    <div className="flex flex-col">
      <Card>
        <CardContent>
          <Accordion type="single" collapsible>
            <AccordionItem value="item-1">
              <AccordionTrigger>
                Aprovim per modifikim te te dhenave
              </AccordionTrigger>
              <AccordionContent className="text-sm">
                Aprovime per pagesa
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </CardContent>
      </Card>
    </div>
  );
};

export default KerkesaAccordion;
