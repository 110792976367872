import { zodResolver } from "@hookform/resolvers/zod";
import { useKeycloak } from "@react-keycloak/web";
import { useMutation } from "@tanstack/react-query";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { Calendar } from "src/components/ui/calendar";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import { Separator } from "src/components/ui/separator";
import { Spinner } from "src/components/ui/spinner";
import { useToast } from "src/components/ui/use-toast";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { cn, isAdmin } from "src/lib/utils";
import { z } from "zod";
import {
  getPlanPagesaPeriodike,
  krijoPlanPagesePeriodike,
  paguajKestin,
} from "../../../api";
import { DataTable } from "../plan-amortizimi/plan-amortizimi-table/DataTable";
import { columns } from "./keste-periodike-table/Columns";

const formSchema = z.object({
  rastId: z.string(),
  interesiVjetor: z
    .string()
    .min(1, { message: "Interesi Vjetor nuk duhet të jetë bosh" }),
  kamatVonese: z
    .string()
    .min(1, { message: "Kamat Vonese nuk duhet të jetë bosh" }),
  monedha: z.string().min(1, { message: "Monedha nuk duhet të jetë bosh" }),
  kursiMonedhes: z
    .string()
    .min(1, { message: "Kursi Monedhes nuk duhet të jetë bosh" }),
  intervali: z.string().min(1, { message: "Intervali nuk duhet të jetë bosh" }),
  detyrimi: z.string().min(1, { message: "Detyrimi nuk duhet të jetë bosh" }),
  numriPagesave: z
    .string()
    .min(1, { message: "Numri pagesave nuk duhet të jetë bosh" }),
  dataNisjes: z.date().refine(
    (date) => {
      return date !== undefined;
    },
    {
      message: "Data duhet te jetë sot ose më vonë",
    }
  ),
});

export interface PlanPagesaKestePeriodike {
  kodiKlientit: string;
  emerPortofoli: string;
  klienti: string;
  statusVerbal: string;
  shumaDetyrimit: number;
  monedha: string;
  interesiVjetor: number;
  kamateVonese: number;
  total: number;
  aprovuar: boolean;
  afati: number;
  keste: Keste[];
}

export interface Keste {
  id: number;
  numriPageses: number;
  vleraPageses: number;
  vleraPrincipale: number;
  vleraInteresit: number;
  vleraMbetur: number;
  dataPageses: string;
  monedha: string;
  paguar: boolean;
}

const KestePeriodike = () => {
  const { rastId } = useParams();
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");
  const [planPagesa, setPlanPagesa] = useState<PlanPagesaKestePeriodike | "">();
  const [date, setDate] = useState<Date | undefined>(new Date());
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const [aprovuarDialog, setAprovuarDialog] = useState(false);
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      rastId: rastId ?? "",
      interesiVjetor: "",
      kamatVonese: "",
      monedha: "LEK",
      kursiMonedhes: "0",
      intervali: "",
      detyrimi: "",
      numriPagesave: "",
      dataNisjes: new Date(),
    },
  });

  useEffect(() => {
    if (form.watch("monedha") === "LEK") {
      form.setValue("kursiMonedhes", "0");
    } else {
      form.setValue("kursiMonedhes", "");
    }
  }, [form.watch("monedha")]);

  const getPlanPagesa = useMutation({
    mutationFn: getPlanPagesaPeriodike,
    onSuccess: (data) => {
      setPlanPagesa(data);
      setAprovuarDialog(!data?.aprovuar);
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: error.message,
      });
      console.log("error", error);
      navigate(`/portofol/rasti/${rastId}`, { replace: true });
    },
  });

  const paguajKestMutation = useMutation({
    mutationFn: paguajKestin,
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Success",
        description: "Kesti u pagua me sukses",
      });
      if (rastId) {
        getPlanPagesa.mutate({ token, rastId });
      }
    },
    onError: (error) => {
      console.log("error", error);
    },
  });
  const krijoPagesePeriodike = useMutation({
    mutationFn: krijoPlanPagesePeriodike,
    onSuccess: (data) => {
      toast({
        variant: "success",
        title: "Success",
        description: "Pagesa u krijua me sukses",
      });
      if (rastId) {
        getPlanPagesa.mutate({ token, rastId });
      }
      setOpen(false);
      handleOpenChange();
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  useEffect(() => {
    if (rastId) {
      getPlanPagesa.mutate({ token, rastId });
    }
  }, [rastId]);

  function onSubmit(values: z.infer<typeof formSchema>) {
    const transformedData = {
      ...values,
      interesiVjetor: parseFloat(values.interesiVjetor),
      kamatVonese: parseFloat(values.kamatVonese),
      kursiMonedhes: parseFloat(values.kursiMonedhes),
      intervali: parseInt(values.intervali, 10),
      detyrimi: parseInt(values.detyrimi, 10),
      numriPagesave: parseInt(values.numriPagesave, 10),
      rastId: rastId ? parseInt(rastId) : 0,
      dataNisjes: format(values.dataNisjes, "yyyy-MM-dd"),
      token,
    };
    krijoPagesePeriodike.mutate(transformedData);
  }

  const handleOpenChange = () => {
    setOpen(!open);
    form.reset();
  };

  const handleOpenDialogChange = () => {
    setAprovuarDialog(!aprovuarDialog);
    navigate(`/portofol/rasti/${rastId}`, { replace: true });
  };

  const handlePay = (id: number, kursi: number) => {
    paguajKestMutation.mutate({ token, kestId: id, kursi });
  };

  if (getPlanPagesa?.isPending) {
    return (
      <div className="px-5 w-1/2 justify-center mt-5">
        <Card>
          <CardHeader className="flex items-center justify-center">
            <CardTitle>Loading...</CardTitle>
          </CardHeader>
          <CardFooter className="flex items-center justify-center">
            <Spinner fill="black" />
          </CardFooter>
        </Card>
      </div>
    );
  } else if (planPagesa === "") {
    return (
      <div className="px-5 w-1/2 justify-center mt-5">
        <Card>
          <CardHeader className="flex items-center justify-center">
            <CardTitle>Nuk ka pagesa per kete rast</CardTitle>
          </CardHeader>
          <CardFooter className="flex items-center justify-center">
            <Dialog open={open} onOpenChange={handleOpenChange}>
              <DialogTrigger asChild>
                <Button variant="default">Krijo pagesen periodike</Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px] max-h-[99%] overflow-auto">
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="grid grid-cols-1 gap-4"
                  >
                    <FormField
                      control={form.control}
                      name="rastId"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Rasti</FormLabel>
                          <FormControl>
                            <Input placeholder="Rasti" {...field} disabled />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="interesiVjetor"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Interesi Vjetor</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="Interesi Vjetor"
                              {...field}
                              type="number"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="kamatVonese"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Kamat Vonesa</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="Kamat Vonesa"
                              type="number"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="monedha"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Monedha</FormLabel>
                          <FormControl>
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button variant="outline" className="w-full">
                                  {field.value || "Zgjidh monedhën"}
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent>
                                <DropdownMenuItem
                                  onClick={() => field.onChange("LEK")}
                                >
                                  LEK
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                  onClick={() => field.onChange("EURO")}
                                >
                                  EURO
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                  onClick={() => field.onChange("USD")}
                                >
                                  USD
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    {form.watch("monedha") !== "LEK" && (
                      <FormField
                        control={form.control}
                        name="kursiMonedhes"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Kursi Monedhes</FormLabel>
                            <FormControl>
                              <Input
                                placeholder="Kursi Monedhes"
                                {...field}
                                type="number"
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                    <FormField
                      control={form.control}
                      name="intervali"
                      render={({ field }) => (
                        <FormItem className="flex flex-col">
                          <FormLabel>Intervali</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="Intervali"
                              {...field}
                              type="number"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="numriPagesave"
                      render={({ field }) => (
                        <FormItem className="flex flex-col">
                          <FormLabel>Numri Pagesave</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="Numri Pagesave"
                              {...field}
                              type="number"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="detyrimi"
                      render={({ field }) => (
                        <FormItem className="flex flex-col">
                          <FormLabel>Detyrimi</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="Detyrimi"
                              {...field}
                              type="number"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="dataNisjes"
                      render={({ field }) => (
                        <FormItem className="flex flex-col">
                          <FormLabel>Data e fillimit</FormLabel>
                          <FormControl>
                            <Popover>
                              <PopoverTrigger asChild>
                                <Button
                                  variant={"outline"}
                                  className={cn(
                                    " justify-start text-left font-normal",
                                    !date && "text-muted-foreground"
                                  )}
                                >
                                  <CalendarIcon className="mr-2 h-4 w-4" />
                                  {date ? (
                                    format(date, "yyyy-MM-dd")
                                  ) : (
                                    <span>Pick a date</span>
                                  )}
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent className="w-auto p-0">
                                <Calendar
                                  mode="single"
                                  selected={date}
                                  onSelect={(selectedDate) => {
                                    setDate(selectedDate);
                                    field.onChange(selectedDate);
                                  }}
                                  initialFocus
                                />
                              </PopoverContent>
                            </Popover>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <Button type="submit">
                      {krijoPagesePeriodike.isPending ? (
                        <Spinner fill="black" />
                      ) : (
                        "Krijo Pagesen"
                      )}
                    </Button>
                  </form>
                </Form>
              </DialogContent>
            </Dialog>
          </CardFooter>
        </Card>
      </div>
    );
  } else {
    return (
      <div className="h-screen relative px-5 w-full mt-5 z-10 flex flex-col gap-4">
        {!isAdmin(keycloak) && (
          <Dialog open={aprovuarDialog} onOpenChange={handleOpenDialogChange}>
            <DialogContent className="sm:max-w-[425px]">
              <DialogTitle>Informacion</DialogTitle>
              <DialogDescription>
                Pagesa e rastit eshte duke u aprovuar
              </DialogDescription>
            </DialogContent>
          </Dialog>
        )}
        <span className="text-secondaryDark font-semibold text-lg">
          Pagesa me keste periodike
        </span>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
          <div className="col-span-1 sm:col-span-2 lg:col-span-1">
            <Card>
              <CardContent className=" p-3 m-3 grid flex-col gap-1">
                <div className="flex flex-row justify-between">
                  <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words overflow-hidden">
                    KODI KLIENTIT:
                  </span>
                  <span>{planPagesa?.kodiKlientit}</span>
                </div>
                <Separator />
                <div className="flex flex-row justify-between">
                  <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words overflow-hidden">
                    EMERTIMI I PORTOFOLIT:
                  </span>
                  <span>{planPagesa?.emerPortofoli}</span>
                </div>
                <Separator />
                <div className="flex flex-row justify-between">
                  <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words overflow-hidden">
                    KLIENTI:
                  </span>
                  <span>{planPagesa?.klienti}</span>
                </div>
                <Separator />
                <div className="flex flex-row justify-between">
                  <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words overflow-hidden">
                    STATUSI:
                  </span>
                  <Button
                    variant="outline"
                    className="border-warning text-warning"
                  >
                    {planPagesa?.statusVerbal}
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="col-span-1 sm:col-span-2 lg:col-span-1">
            <Card>
              <CardContent className=" p-3 m-3 grid flex-col gap-1">
                <div className="flex flex-row justify-between">
                  <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words overflow-hidden">
                    SHUMA E DETYRIMIT:
                  </span>
                  <span>{planPagesa?.shumaDetyrimit}</span>
                </div>
                <Separator />
                <div className="flex flex-row justify-between">
                  <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words overflow-hidden">
                    MONEDHA:
                  </span>
                  <span>{planPagesa?.monedha}</span>
                </div>
                <Separator />
                <div className="flex flex-row justify-between">
                  <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words overflow-hidden">
                    AFATI:
                  </span>
                  <span>{`${planPagesa?.afati} Muaj`}</span>
                </div>
                <Separator />
                <div className="flex flex-row justify-between">
                  <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words overflow-hidden">
                    INTERESI VJETOR:
                  </span>
                  <span>{`${planPagesa?.interesiVjetor} %`}</span>
                </div>
                <Separator />
                <div className="flex flex-row justify-between">
                  <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words overflow-hidden">
                    kamatvonese:
                  </span>
                  <span>{`${planPagesa?.kamateVonese} %`}</span>
                </div>
                <Separator />
                <div className="flex flex-row justify-between">
                  <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words overflow-hidden">
                    totali:
                  </span>
                  <span>{planPagesa?.total}</span>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="w-full flex flex-row justify-between">
          <span className="text-sm">Tabela e pagesave</span>
          <Button variant="link" className="text-secondary">
            Shiko me shume +
          </Button>
        </div>
        {planPagesa && planPagesa?.keste?.length > 0 && (
          <DataTable
            data={planPagesa.keste}
            columns={columns}
            handlePay={handlePay}
            paguajKestMutation={paguajKestMutation}
          />
        )}
        <Button variant="secondary" className="w-[200px] text-white">
          Shto kest
        </Button>
      </div>
    );
  }
};

export default KestePeriodike;
