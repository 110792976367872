import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { getSpecialList } from "../../api";
import PortofolCards from "../portofoli/PortofolCards";
import { DataTable } from "../portofoli/portofoli-table/DataTable";
import { Portofoli } from "../types/tabela-portofol";
import { Card, CardFooter, CardHeader, CardTitle } from "../ui/card";
import { Spinner } from "../ui/spinner";
import { columns } from "./special-list-table/Columns";

const SpecialList = () => {
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");
  const filterData = getItem("filter");
  const [rastDetailsData, setRastDetailsData] = useState<Portofoli>();

  const transformData = (data: any) => {
    return data.map((item: any) => {
      return {
        ...item,
        segmenti: filterData.segmenti[item.segmenti] || item.segmenti,
        klasifikimKredie:
          filterData.klasifikimKredie[item.klasifikimKredie] ||
          item.klasifikimKredie,
        ekSipasMaturimit:
          filterData.ekSipasMaturimit[item.ekSipasMaturimit] ||
          item.ekSipasMaturimit,
        situateLigjore:
          filterData.situateLigjore[item.situateLigjore] || item.situateLigjore,
        statusVerbal:
          filterData.statusVerbal[item.statusVerbal] || item.statusVerbal,
        fazaPermbarimit:
          filterData.fazaPermbarimit[item.fazaPermbarimit] ||
          item.fazaPermbarimit,
      };
    });
  };

  useEffect(() => {
    getSpecialListData.mutate({ token });
  }, [token]);

  const getSpecialListData = useMutation({
    mutationFn: getSpecialList,
    onSuccess: (data) => {
      let transformedData = transformData(data.raste);
      setRastDetailsData({
        ...data,
        raste: transformedData,
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  if (getSpecialListData?.isPending) {
    return (
      <div className="px-5 w-1/2 justify-center mt-5">
        <Card>
          <CardHeader className="flex items-center justify-center">
            <CardTitle>Loading...</CardTitle>
          </CardHeader>
          <CardFooter className="flex items-center justify-center">
            <Spinner fill="black" />
          </CardFooter>
        </Card>
      </div>
    );
  }

  return (
    <div className="h-screen w-full relative px-5 gap-4 flex flex-col">
      {rastDetailsData && <PortofolCards portofolDetails={rastDetailsData} />}
      {rastDetailsData?.raste && (
        <DataTable data={rastDetailsData.raste} columns={columns} />
      )}
    </div>
  );
};

export default SpecialList;
