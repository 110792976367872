import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "src/components/portofoli/portofoli-table/DataTableColumnHeader";
import { Raste } from "src/components/types/tabela-portofol.ts";
import { Button } from "src/components/ui/button.tsx";

export const columns: ColumnDef<Raste>[] = [
  {
    accessorKey: "kodiKlientit",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Kodi i Klientit" />
    ),
    // cell: ({ row }) => <div className="w-[80px]">{row.getValue("id")}</div>,
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "portofoli",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Emërtimi i Portofolit" />
    ),
    // cell: ({ row }) => {
    //   const label = labels.find((label) => label.value === row.original.label);

    //   return (
    //     <div className="flex space-x-2">
    //       {label && <Badge variant="outline">{label.label}</Badge>}
    //       <span className="max-w-[500px] truncate font-medium">
    //         {row.getValue("title")}
    //       </span>
    //     </div>
    //   );
    // }
  },
  {
    accessorKey: "emerMbiemer",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Emer Mbiemer" />
    ),
    // cell: ({ row }) => {
    //   const label = labels.find((label) => label.value === row.original.label);

    //   return (
    //     <div className="flex space-x-2">
    //       {label && <Badge variant="outline">{label.label}</Badge>}
    //       <span className="max-w-[500px] truncate font-medium">
    //         {row.getValue("title")}
    //       </span>
    //     </div>
    //   );
    // },
  },
  {
    accessorKey: "segmenti",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Segmenti" />
    ),
    // cell: ({ row }) => {
    //   const label = labels.find((label) => label.value === row.original.label);

    //   return (
    //     <div className="flex space-x-2">
    //       {label && <Badge variant="outline">{label.label}</Badge>}
    //       <span className="max-w-[500px] truncate font-medium">
    //         {row.getValue("title")}
    //       </span>
    //     </div>
    //   );
    // },
  },
  {
    accessorKey: "dega",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Rrethi/Dega" />
    ),
    // cell: ({ row }) => {
    //   const label = labels.find((label) => label.value === row.original.label);

    //   return (
    //     <div className="flex space-x-2">
    //       {label && <Badge variant="outline">{label.label}</Badge>}
    //       <span className="max-w-[500px] truncate font-medium">
    //         {row.getValue("title")}
    //       </span>
    //     </div>
    //   );
    // },
  },
  {
    accessorKey: "statusSigurie",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Secured/Unsecured" />
    ),
    cell: ({ row }) => {
      const value = row.getValue("statusSigurie") as boolean | undefined;
      if (value !== undefined) {
        return (
          <Button
            variant="outline"
            className={`${
              value
                ? "border-success text-success"
                : "border-danger text-danger"
            }`}
          >
            {value === true ? "Secured" : "Unsecured"}
          </Button>
        );
      }
      // Optionally, handle the case where value is undefined or null
      return <span>No Status Available</span>;
    },
  },
  {
    accessorKey: "situateLigjore",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Situata Ligjore" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "currency",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Currency" />
    ),
  },
  {
    accessorKey: "sipasUE",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Vlera e Detyrimit Sipas UE"
      />
    ),
    // cell: ({ row }) => {
    //   const value = row.getValue("sipasUE") as SipasUe | undefined;

    //   if (value) {
    //     const { shuma, monedha } = value;
    //     return `${shuma} ${monedha?.kodi || ''}`;
    //   }
    //   return null;
    // },
  },
  {
    accessorKey: "vleraNePermbarim",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Vlera e Detyrimit të përmbarimit"
      />
    ),
    // cell: ({ row }) => {
    //   const value = row.getValue("vleraBlerjesDetyrimit") as VleraBlerjesDetyrimit | undefined;

    //   if (value) {
    //     const { shuma, monedha } = value;
    //     return `${shuma} ${monedha?.kodi || ''}`;
    //   }
    //   return null;
    // },
  },
  {
    accessorKey: "zyraPermbarimore",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Emri i zyres permbarimore"
      />
    ),
  },
  {
    accessorKey: "fazaPermbarimit",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Faza e Procedurës Përmbarimore"
      />
    ),
  },
  {
    accessorKey: "statusVerbal",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => {
      return (
        <Button
          variant="outline"
          className={`flex-nowrap text-nowrap border-warning text-warning`}
        >
          {row.getValue("statusVerbal")}
        </Button>
      );
    },
  },
  {
    id: "actions",
    // cell: ({ row }) => <DataTableRowActions row={row} />,
  },
];
