import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "src/lib/utils";
import { Specialist } from "../types/tabela-portofol";
import { Button } from "../ui/button";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

interface SpecialistPopoverProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  specialistet: Specialist[];
  value: string;
  setValue: (value: string) => void;
  setSpecialist: (specialist: Specialist | undefined) => void;
  specialist: Specialist | undefined;
  reportType: string;
}

const SpecialistPopover: React.FC<SpecialistPopoverProps> = ({
  open,
  setOpen,
  specialistet,
  value,
  setValue,
  setSpecialist,
  specialist,
  reportType,
}) => {
  const getSpecialistName = (id: number | undefined) => {
    const specialist = specialistet.find((s) => s.id === id);
    return specialist
      ? `${specialist.emri} ${specialist.mbiemer}`
      : "Kerko Specialist";
  };

  const getPermbaruesName = (id: number | undefined) => {
    const specialist = specialistet.find((s) => s.id === id);
    return specialist ? specialist.name : "Kerko Permbarues";
  };

  const displayName = () => {
    if (reportType === "raport-permbarues") {
      return value ? getPermbaruesName(specialist?.id) : "Kerko Permbarues";
    } else {
      return value ? getSpecialistName(specialist?.id) : "Kerko Specialist";
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {displayName()}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput
            placeholder={
              reportType === "raport-permbarues"
                ? "Kerko nje permbarues..."
                : "Kerko nje specialist..."
            }
          />
          <CommandList>
            {specialistet.length > 0 ? (
              specialistet.map((specialist) => (
                <CommandItem
                  key={specialist.id}
                  value={specialist.emri}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? "" : currentValue);
                    setSpecialist(
                      currentValue === value
                        ? undefined
                        : specialistet.find((s) => s.id === specialist.id)
                    );
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === specialist.emri || value === specialist.name
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                  {reportType === "raport-permbarues"
                    ? specialist.name
                    : `${specialist.emri} ${specialist.mbiemer}`}
                </CommandItem>
              ))
            ) : (
              <CommandEmpty>No results found.</CommandEmpty>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default SpecialistPopover;
