import { useKeycloak } from "@react-keycloak/web";
import { format } from "date-fns";
import { Bell, ChevronLeft, LogOutIcon, Settings } from "lucide-react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import useCustomHistory from "src/hooks/useCustomHistory";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

function getDisplayTime() {
  return format(new Date(), "EEE, MMM dd, H:mm:ss");
}

export default function Header({ setIsSidebar }: any) {
  const [clockText, setClockText] = useState(getDisplayTime());
  const { selectedNavbarItem } = useAppSelector(
    (state: RootState) => state.userData
  );
  const { setItem } = useLocalStorage("selectedNavbarItem");
  const location = useLocation();

  const { history, goBack } = useCustomHistory();

  const { keycloak } = useKeycloak();

  const handleLogout = async () => {
    const redirectUri = window.location.origin + "/";
    await keycloak
      .logout({
        redirectUri,
      })
      .catch((error) => {
        console.error("Logout failed", error);
      });
    setItem({
      id: 1,
      title: "Te gjithe portofolet",
      color: "text-white-500",
      href: "/portofole",
      icon: {},
    });
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setClockText(getDisplayTime());
  //   }, 1000);
  //   return () => clearInterval(intervalId);
  // }, []);

  return keycloak.authenticated ? (
    <div
      className="top-0 z-10 border-b backdrop-blur bg-cover flex justify-center animate-in duration-500 h-[200px]"
      style={{
        backgroundImage: `url(${require("../../assets/images/header-image-bg.png")})`,
      }}
    >
      <div className="absolute left-5 bottom-5 text-white flex items-center gap-2">
        {location.pathname !== "/" && location.pathname !== "/portofole" && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant={"default"}
                  size={"icon"}
                  className="bg-secondaryLight border border-secondaryLight"
                  onClick={goBack}
                >
                  <ChevronLeft color="white" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Go Back</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
        {selectedNavbarItem.title}
      </div>

      <div className="grid grid-cols-4 bg-transparent h-10 rounded-2xl border items-center self-center justify-center w-4/5">
        <div className="col-span-4 flex flex-row items-center gap-4 mx-2">
          <Input
            className="+
          border-none hover:border-none focus-visible:outline-none focus-visible:ring-0 focus-visible:normal-case placeholder:text-white text-white"
            placeholder="Search by name, business"
            type="text"
          />
          <Bell className="flex-shrink-0" color="white" size={18} />
          <Settings className="flex-shrink-0" color="white" size={18} />
          {keycloak.profile?.username && (
            <Label className="text-white flex flex-shrink-0 h-10 items-center font-semibold">
              Hi {keycloak.profile?.username}
            </Label>
          )}
          <Button
            variant={"link"}
            className="text-white shadow-sm items-center flex-shrink-0 underline max-md:hidden"
            onClick={handleLogout}
          >
            Logout
          </Button>
          <LogOutIcon
            className="flex-shrink-0 md:hidden"
            color="white"
            size={18}
          />
        </div>
      </div>
    </div>
  ) : null;
}
