import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { getPortofolData, getPortofolDetails } from "../../api";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "../ui/dialog";
import { Separator } from "../ui/separator";
import { Spinner } from "../ui/spinner";

const RakordimetFinanciare = () => {
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");
  const [portofole, setPortofole] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [portofolDetails, setPortofolDetails] = useState<any>();

  const portofolMutation = useMutation({
    mutationFn: getPortofolData,
    onSuccess: (data: any) => {
      setPortofole(data?.content);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const portofolDetailsMutation = useMutation({
    mutationFn: getPortofolDetails,
    onSuccess: (data: any) => {
      setPortofolDetails(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    portofolMutation.mutate(token);
  }, []);

  const handleOpenChange = (portofolId: string) => {
    portofolDetailsMutation.mutate({ token, portofolId });
    setOpen(!open);
  };

  return (
    <div className="px-5 relative w-full mt-5 flex flex-col gap-3">
      <span className="text-secondaryDark font-semibold text-lg">
        Zgjidh Portofolin
      </span>
      <div className="grid grid-cols-1 w-full gap-4">
        {portofolMutation?.isPending ? (
          <Card>
            <CardHeader className="justify-center items-center">
              <CardTitle className="text-sm">Loading...</CardTitle>
            </CardHeader>
          </Card>
        ) : portofole?.length > 0 ? (
          portofole.map((portofol: any, index: any) => {
            return (
              <Card key={index}>
                <CardHeader>
                  <CardTitle className="text-sm">{portofol.name}</CardTitle>
                </CardHeader>
                <div className="flex flex-col sm:flex-row justify-around row-span-1 sm:row-span-2 lg:row-span-3 gap-4">
                  <CardContent className="w-full sm:w-1/3 flex gap-3 items-center justify-center">
                    <p className="text-sm">VLERA TOTALE</p>
                    <p className="font-semibold text-sm">
                      {portofol.vleraTotale}
                    </p>
                  </CardContent>
                  <CardContent className="w-full sm:w-1/3 flex gap-3 items-center justify-center">
                    <p className="text-sm">VLERA E PORTOFOLIT</p>
                    <p className="font-semibold text-sm">
                      {portofol.vleraPortofolit}
                    </p>
                  </CardContent>
                  <CardContent className="w-full sm:w-1/3 flex gap-3 items-center justify-center">
                    <p className="text-sm">ARKËTIME (DERI ME TANI)</p>
                    <p className="font-semibold text-sm">{portofol.arketim}</p>
                  </CardContent>
                  <CardContent className=" w-full sm:w-1/3 flex gap-3 items-center justify-center">
                    <Dialog
                      open={open}
                      onOpenChange={() => handleOpenChange(portofol.id)}
                    >
                      <DialogTrigger asChild>
                        <Button variant="default">Me Shume</Button>
                      </DialogTrigger>

                      {portofolDetailsMutation.isPending ? (
                        <DialogContent>
                          <Card>
                            <CardHeader className="flex items-center justify-center">
                              <CardTitle>Loading...</CardTitle>
                            </CardHeader>
                            <CardFooter className="flex items-center justify-center">
                              <Spinner fill="black" />
                            </CardFooter>
                          </Card>
                        </DialogContent>
                      ) : (
                        <DialogContent>
                          <DialogHeader>{portofolDetails?.name}</DialogHeader>
                          <Card>
                            <CardContent className=" p-3 m-3 grid flex-col gap-1">
                              <div className="flex flex-row justify-between">
                                <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words overflow-hidden">
                                  VLERA TOTALE E PORTOFOLIT:
                                </span>
                                <span>{portofolDetails?.vleraTotale}</span>
                              </div>
                              <Separator />
                              <div className="flex flex-row justify-between">
                                <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words overflow-hidden">
                                  VLERA E PORTOFOLIT Ë MOMENTIN E BLERJES:
                                </span>
                                <span>{portofolDetails?.vleraPortofolit}</span>
                              </div>
                              <Separator />
                              <div className="flex flex-row justify-between">
                                <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words overflow-hidden">
                                  OUTSTANDING BOA:
                                </span>
                                <span>{portofolDetails?.outstandingBoa}</span>
                              </div>
                              <Separator />
                              <div className="flex flex-row justify-between">
                                <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words overflow-hidden">
                                  DATA:
                                </span>
                                <span>{portofolDetails?.dateUploaded}</span>
                              </div>
                              <Separator />
                              <div className="flex flex-row justify-between">
                                <span className="uppercase text-sm p-1 w-1/2 whitespace-normal break-words overflow-hidden">
                                  FX:
                                </span>
                              </div>
                            </CardContent>
                          </Card>
                        </DialogContent>
                      )}
                    </Dialog>
                  </CardContent>
                </div>
              </Card>
            );
          })
        ) : (
          <Card>
            <CardHeader className="justify-center items-center">
              <CardTitle className="text-sm">Nuk ka te dhena</CardTitle>
            </CardHeader>
          </Card>
        )}
      </div>
    </div>
  );
};

export default RakordimetFinanciare;
