import { useMutation } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { differenceInDays, format } from "date-fns";
import { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import {
  getPermbaruesList,
  getRaporteArketime,
  getRaporteArketueshme,
  getRaporteEvidencaKreditit,
  getRaporteKlasifikimKredie,
  getRaporteMaturim,
  getRaportePastDue,
  getRaportePermbarues,
  getRaporteSecured,
  getRaporteSipasSegmentit,
  getRaporteSituateLigjore,
  getSpecialistet,
} from "../../api";
import { Specialist } from "../types/tabela-portofol";
import { Button } from "../ui/button";
import { Card, CardContent } from "../ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Spinner } from "../ui/spinner";
import { generateColumns } from "./arketime-table/Columns";
import { DataTable } from "./arketime-table/DataTable";
import DatePopover from "./DatePopover";
import SpecialistPopover from "./SpecialistPopover";

const Arketimet = () => {
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");
  const [tableData, setTableData] = useState<[]>([]);
  const [value, setValue] = useState<string>("arketime");
  const [specialistValue, setSpecialistValue] = useState<string>("");
  const [specialistet, setSpecialistet] = useState<Specialist[]>([]);
  const [specialist, setSpecialist] = useState<Specialist | undefined>();
  const [permbaruesList, setPermbaruesList] = useState<[]>([]);
  const [permbarues, setPermbarues] = useState<any>();
  const [permbaruesValue, setPermbaruesValue] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [numDays, setNumDays] = useState<number | null>(null);
  const [date, setDate] = useState<DateRange | undefined>({
    from: new Date(),
    to: new Date(),
  });
  const [columns, setColumns] = useState<ColumnDef<any>[]>([]);
  const [error, setError] = useState<string | null>(null);

  const dropdownOptions = [
    {
      id: 1,
      name: "arketime",
      label: "Arketime",
    },
    {
      id: 2,
      name: "klasifikim-kredie",
      label: "Klasifikim Kredie",
    },
    {
      id: 3,
      name: "evidenca-kreditit",
      label: "Evidenca Kreditit",
    },
    {
      id: 4,
      name: "maturim",
      label: "Maturim",
    },
    {
      id: 5,
      name: "te-arketueshme",
      label: "Te Arketueshme",
    },
    {
      id: 6,
      name: "past-due",
      label: "Past Due",
    },
    {
      id: 7,
      name: "situate-ligjore",
      label: "Situate Ligjore",
    },
    {
      id: 8,
      name: "sipas-segmentit",
      label: "Sipas Segmentit",
    },
    {
      id: 9,
      name: "secured-unsecured",
      label: "Secured/Unsecured",
    },
    {
      id: 10,
      name: "raport-permbarues",
      label: "Raport Permbarues",
    },
  ];

  useEffect(() => {
    if (date?.from && date.to) {
      const days = differenceInDays(date.to, date.from);
      setNumDays(days);
    }
  }, [date]);

  useEffect(() => {
    setDate({ from: new Date(), to: new Date() });
    setSpecialist(undefined);
    setSpecialistValue("");
    setPermbaruesValue("");
  }, [value]);

  const specialistetMutation = useMutation({
    mutationFn: getSpecialistet,
    onSuccess: (data) => setSpecialistet(data.content),
    onError: (error) => console.log(error),
  });

  const permbaruesMutation = useMutation({
    mutationFn: getPermbaruesList,
    onSuccess: (data) => setPermbaruesList(data),
    onError: (error) => console.log(error),
  });

  const fetchReportData = (reportType: string) => {
    switch (reportType) {
      case "arketime":
        return getRaporteArketime;
      case "klasifikim-kredie":
        return getRaporteKlasifikimKredie;
      case "evidenca-kreditit":
        return getRaporteEvidencaKreditit;
      case "past-due":
        return getRaportePastDue;
      case "maturim":
        return getRaporteMaturim;
      case "te-arketueshme":
        return getRaporteArketueshme;
      case "situate-ligjore":
        return getRaporteSituateLigjore;
      case "secured-unsecured":
        return getRaporteSecured;
      case "raport-permbarues":
        return getRaportePermbarues;
      case "sipas-segmentit":
        return getRaporteSipasSegmentit;
      default:
        return getRaporteArketime;
    }
  };

  const reportDataMutation = useMutation({
    mutationFn: (body: any) => fetchReportData(value)(body),
    onSuccess: (data) => {
      setTableData(data);
      setColumns(generateColumns(data[0] || {}));
      setError(null);
    },
    onError: (error) => {
      console.log(error);
      setError(error.message);
    },
  });

  useEffect(() => {
    if (value) {
      const body = {
        specialistId: 0,
        from: format(new Date(), "yyyy-MM-dd"),
        to: format(new Date(), "yyyy-MM-dd"),
        token,
      };
      reportDataMutation.mutate(body);
    }
  }, [value]);

  useEffect(() => {
    specialistetMutation.mutate({ token });
    permbaruesMutation.mutate({ token });
  }, [token]);

  const handleApliko = () => {
    const body = {
      specialistId:
        value === "raport-permbarues"
          ? permbarues?.id || 0
          : specialist?.id || 0,
      from: date?.from ? format(date.from, "yyyy-MM-dd") : "",
      to: date?.to ? format(date.to, "yyyy-MM-dd") : "",
      token,
    };
    reportDataMutation.mutate(body);
  };

  const renderContent = () => {
    if (reportDataMutation.isPending) {
      return (
        <div className=" col-span-4 border border-white bg-white rounded-xl shadow w-full flex items-center justify-center h-full">
          <Spinner fill="black" />
        </div>
      );
    } else if (error) {
      return (
        <div className="col-span-4 border border-white bg-white rounded-xl shadow w-full flex items-center justify-center h-full text-red-500">
          {error}
        </div>
      );
    } else {
      return (
        <div className="col-span-4 border border-white  bg-white rounded-xl shadow">
          {!!value && (
            <div className="w-full flex flex-row gap-3 space-x-2 p-2">
              <SpecialistPopover
                open={open}
                setOpen={setOpen}
                specialistet={
                  value === "raport-permbarues" ? permbaruesList : specialistet
                }
                value={
                  value === "raport-permbarues"
                    ? permbaruesValue
                    : specialistValue
                }
                setValue={
                  value === "raport-permbarues"
                    ? setPermbaruesValue
                    : setSpecialistValue
                }
                setSpecialist={
                  value === "raport-permbarues" ? setPermbarues : setSpecialist
                }
                specialist={
                  value === "raport-permbarues" ? permbarues : specialist
                }
                reportType={value}
              />
              <DatePopover date={date} setDate={setDate} />
              <Button onClick={handleApliko} variant={"default"}>
                {reportDataMutation.isPending ? (
                  <Spinner fill="black" />
                ) : (
                  "Apliko"
                )}
              </Button>
            </div>
          )}
          {!!value && (
            <DataTable
              data={tableData}
              columns={columns}
              specialist={specialist}
              date={date}
              value={value}
              permbarues={permbarues}
            />
          )}
        </div>
      );
    }
  };

  return (
    <div className="px-5 relative w-full mt-5 flex flex-col gap-3">
      <div className="grid grid-cols-5 h-auto gap-2 ">
        {renderContent()}
        <div className="col-span-1">
          <Card className="h-[150px] flex items-center w-full">
            <CardContent className="w-full">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" className="w-full">
                    {value
                      ? dropdownOptions.find((o) => o.name === value)?.label
                      : "Lista e Arketimeve"}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="center"
                  className="w-64 overflow-y-auto max-h-[50vh]"
                >
                  {dropdownOptions.map((option) => (
                    <DropdownMenuItem
                      key={option.id}
                      onClick={() => setValue(option.name)}
                    >
                      {option.label}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Arketimet;
