import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavItems } from "src/components/constants/side-nav";
import { useLocalStorage } from "./useLocalStorage";

const useCustomHistory = () => {
  const { setItem: setPathHistoryItem, getItem: getPathHistoryItem } =
    useLocalStorage("pathHistory");
  const { setItem: setSelectedNavbarItem } =
    useLocalStorage("selectedNavbarItem");
  const storedHistory = getPathHistoryItem("pathHistory");

  const [history, setHistory] = useState<string[]>(storedHistory || []);
  const location = useLocation();
  const navigate = useNavigate();

  const MAX_HISTORY_LENGTH = 20;

  useEffect(() => {
    setHistory((prevHistory) => {
      const newPath = location.pathname;
      if (prevHistory[prevHistory.length - 1] !== newPath) {
        let newHistory = [...prevHistory, newPath];
        if (newHistory.length > MAX_HISTORY_LENGTH) {
          newHistory = newHistory.slice(newHistory.length - MAX_HISTORY_LENGTH);
        }
        setPathHistoryItem(newHistory);
        return newHistory;
      }
      return prevHistory;
    });
  }, [location]);

  const goBack = () => {
    setHistory((prevHistory) => {
      const newHistory = prevHistory.slice(0, -1);
      setPathHistoryItem(newHistory);
      const prevPath = newHistory[newHistory.length - 1] || "/";
      navigate(prevPath);
      const result = NavItems.find((item) =>
        item.children?.find((child) => child.href === prevPath)
      );
      const foundObj = result?.children?.find(
        (child) => child.href === prevPath
      );
      if (foundObj) {
        setSelectedNavbarItem(foundObj);
      }
      return newHistory;
    });
  };

  return { history, goBack };
};

export default useCustomHistory;
