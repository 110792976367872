import { ColumnDef } from "@tanstack/react-table";
import { Raste } from "src/components/types/tabela-portofol.ts";
import { Button } from "src/components/ui/button";
import { DataTableColumnHeader } from "./DataTableColumnHeader";

export const columns: ColumnDef<Raste | any | undefined>[] = [
  {
    accessorKey: "emri",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Emri i Shoqerise" />
    ),
    // cell: ({ row }) => {
    //   const dega = row.original && row.original.dega ? row.original.dega : "";
    //   return dega;
    // },
  },
  {
    accessorKey: "currency",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Evidenca e kreditit sipas degëve të ekonomisë"
      />
    ),
    cell: ({ row }) => {
      const currency =
        row.original && row.original.currency ? row.original.currency : "";
      return currency;
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Statusi i Shoqërisë" />
    ),
    cell: ({ row }) => {
      const value = row.getValue("status") as string | undefined;
      if (value) {
        return (
          <Button
            variant="outline"
            className={`flex-nowrap text-nowrap ${
              value === "Aktiv"
                ? "border-success text-success"
                : "border-danger text-danger"
            }`}
          >
            {value === "Aktiv" ? "Aktiv" : "Jo Aktiv"}
          </Button>
        );
      }
    },
  },
  {
    accessorKey: "nipt",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="NUIS" />
    ),
  },
  {
    accessorKey: "telQKB",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Telefon QKB" />
    ),
    // cell: ({ row }) => {
    //   const vleraPermbarimitLeke = row.original && row.original.vleraLekeMomentiBlerjes ? row.original.vleraLekeMomentiBlerjes : "";
    //   return vleraPermbarimitLeke;
    // },
  },
  {
    accessorKey: "emailQKB",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Email QKB" />
    ),
  },
  {
    accessorKey: "administrator",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Adresa" />
    ),
    cell: ({ row }) => {
      const konvertimiNeEuro =
        row.original && row.original.konvertimiNeEuro
          ? row.original.konvertimiNeEuro
          : "";
      return konvertimiNeEuro;
    },
  },
  {
    accessorKey: "dataMarreveshjes",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Qyteti" />
    ),
    cell: ({ row }) => {
      const dataMarreveshjes =
        row.original && row.original.dataMarreveshjes
          ? row.original.dataMarreveshjes
          : "";
      return dataMarreveshjes;
    },
  },
  {
    accessorKey: "shumeMarveshjes",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Shteti" />
    ),
    cell: ({ row }) => {
      const shumeMarveshjes =
        row.original && row.original.shumeMarveshjes
          ? row.original.shumeMarveshjes
          : "";
      return shumeMarveshjes;
    },
  },
  // {
  //   id: "actions",
  //   cell: ({ row }) => <DataTableRowActionsDorezanes row={row} />,
  // },
];

export default columns;
