import { Upload } from "lucide-react";
import React, { ChangeEvent } from "react";
import { Button } from "src/components/ui/button";
import { Spinner } from "src/components/ui/spinner";

interface FileUploadButtonProps {
  id: string;
  isPending: boolean;
  selectedFile?: File;
  isDisabled?: boolean;
  isError?: boolean;
  handleFileChange: (e: ChangeEvent<HTMLInputElement>, id: string) => void;
  label: string;
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  id,
  isPending,
  selectedFile,
  isDisabled = false,
  isError = false,
  handleFileChange,
  label,
}) => (
  <div className="grid w-full max-w-sm items-center gap-1.5">
    <label htmlFor={id}>
      <Button
        variant="outline"
        className={`flex items-center ${
          isPending ? "justify-center" : "justify-between"
        } gap-2 w-full ${isError ? "bg-danger" : "bg-secondary"} text-white`}
        onClick={() => document.getElementById(id)?.click()}
      >
        {isPending ? (
          <Spinner fill="black" />
        ) : selectedFile ? (
          selectedFile.name
        ) : (
          `Ngarko ${label}`
        )}
        {!isPending && <Upload size={16} />}
      </Button>
    </label>
    <input
      id={id}
      type="file"
      className="hidden"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      onChange={(e) => handleFileChange(e, id)}
    />
  </div>
);

export default FileUploadButton;
