import { useKeycloak } from "@react-keycloak/web";
import { isAdmin } from "src/lib/utils";
import { Specialist } from "../types/tabela-portofol";
import KerkesatAdmin from "./KerkesatAdmin";
import KerkesatUser from "./KerkesatUser";

export interface KerkesatPending {
  id: number;
  planPageseId: number;
  specialistId: number;
  statusAprovimi: string;
  koment: string;
  pershkrimi: string;
  specialist: Specialist;
}

const Kerkesat = () => {
  const { keycloak } = useKeycloak();

  return isAdmin(keycloak) ? <KerkesatAdmin /> : <KerkesatUser />;
};

export default Kerkesat;
