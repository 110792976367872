import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "./DataTableColumnHeader.tsx";

export const columnsPerson: ColumnDef<any>[] = [
  {
    accessorKey: "emri",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Emri" />
    ),
  },
  {
    accessorKey: "mbiemer",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Mbiemri" />
    ),
  },
  {
    accessorKey: "atesia",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Atesia" />
    ),
  },
  {
    accessorKey: "datelindje",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Datelindja" />
    ),
  },
  {
    accessorKey: "adresa",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Adresa" />
    ),
    cell: ({ row }) => {
      return row.original.adrese?.street;
    },
  },
  {
    accessorKey: "qyteti",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Qyteti" />
    ),
    cell: ({ row }) => {
      return row.original.adrese?.city;
    },
  },
  {
    accessorKey: "shteti",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Shteti" />
    ),
    cell: ({ row }) => {
      return row.original.adrese?.country;
    },
  },
  {
    accessorKey: "telefoni",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Nr. Telefoni" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex flex-col">
          {row.original.tel?.map((nrTel: string, idx: number) => (
            <span key={idx}>{nrTel}</span>
          ))}
        </div>
      );
    },
  },
  {
    accessorKey: "email",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Email" />
    ),
  },
];

export default columnsPerson;
