import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { addKoment, getKomentet } from "../../api";
import { Person, Raste } from "../types/tabela-portofol";
import { Button } from "../ui/button";
import { Card, CardContent } from "../ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import KomentComponent from "./KomentComponent";

export interface Comment {
  id: number;
  komenti: string;
  person: Person;
  rast: Raste;
  data: string;
}

interface CommentBoxProps {
  rastId: string;
}

const CommentBox = ({ rastId }: CommentBoxProps) => {
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");

  const [comments, setComments] = useState<Comment[]>([]);
  const [commentText, setCommentText] = useState<string>("");
  const scroll = useRef<HTMLDivElement>(null);

  const merrKomentetMutation = useMutation({
    mutationFn: getKomentet,
    onSuccess: (data) => {
      setComments(data);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const addKomentMutation = useMutation({
    mutationFn: addKoment,
    onSuccess: () => {
      merrKomentetMutation.mutate({ token, rastId });
      setCommentText("");
    },
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    if (rastId) {
      const body = {
        token,
        rastId,
      };
      merrKomentetMutation.mutate(body);
    }
  }, []);

  const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCommentText(e.target.value);
  };

  const handleCommentSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (commentText.trim()) {
      const body = {
        token,
        rastId,
        koment: commentText,
      };
      addKomentMutation.mutate(body);
      setCommentText("");
      scroll.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleReply = (id: number, text: string) => {
    //   // return comments.map((comment) => {
    //   //   if (comment.id === id) {
    //   //     return {
    //   //       ...comment,
    //   //       replies: [
    //   //         ...comment.replies,
    //   //         {
    //   //           id: nextId,
    //   //           author: 'User',
    //   //           text: text,
    //   //           date: new Date().toLocaleString(),
    //   //           replies: [],
    //   //         },
    //   //       ],
    //   //     };
    //   //   }
    //   //   return {
    //   //     ...comment,
    //   //     replies: addReplyToComment(comment.replies),
    //   //   };
    //   // });
    // };
    // setComments(addReplyToComment(comments));
    // setNextId(nextId + 1);
    // scroll.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Card>
      <CardContent className="flex-row p-3 flex justify-center items-center">
        <Dialog>
          <DialogTrigger asChild className="w-full">
            <Button variant="outline">Shiko te gjitha komentet</Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[600px] h-full flex flex-col ">
            <DialogHeader>
              <DialogTitle>Komente</DialogTitle>
            </DialogHeader>
            <DialogDescription
              ref={scroll}
              className="overflow-auto w-full flex-grow"
            >
              {comments.length > 0 ? (
                comments.map((comment) => (
                  <KomentComponent
                    key={comment.id}
                    comment={comment}
                    onReply={handleReply}
                  />
                ))
              ) : (
                <div className="flex justify-center items-center h-full">
                  <p>Nuk ka komente.</p>
                </div>
              )}
            </DialogDescription>
            <DialogFooter className="w-fulljustify-center flex bottom-0 p-4">
              <form className="mt-4 w-full" onSubmit={handleCommentSubmit}>
                <textarea
                  className="w-full p-2 border rounded-md mb-2"
                  value={commentText}
                  onChange={handleCommentChange}
                  placeholder="Shkruaj komentin tend..."
                />
                <Button type="submit" className="w-full">
                  Shto koment
                </Button>
              </form>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default CommentBox;
