import {
  Archive,
  FileClock,
  GitPullRequest,
  Landmark,
  LayoutDashboard,
  ListTree,
  SquareX,
  Users,
} from "lucide-react";
import { type NavItem } from "../types/index";

export const NavItems: NavItem[] = [
  {
    id: 1,
    title: "Home",
    icon: undefined,
    href: "",
    color: "text-orange-500",
    isChidren: true,
    children: [
      {
        id: 1,
        title: "Te gjithe portofolet",
        icon: LayoutDashboard,
        color: "text-white-500",
        href: "/portofole",
      },
      {
        id: 2,
        title: "Vlera e Rakordimeve Financiare",
        icon: Archive,
        color: "text-white-500",
        href: "/rakordimet-financiare",
      },
    ],
    roles: ["admin", "user"],
  },
  {
    id: 2,
    title: "Pages",
    icon: undefined,
    href: "",
    color: "text-orange-500",
    isChidren: true,
    children: [
      {
        id: 1,
        title: "Arkëtimet",
        icon: Landmark,
        color: "text-white-500",
        href: "/arketimet",
        // dropDownItems: [
        //   { id: 0, label: "Per Specialistet", href: "/arketimet/specialistet" },
        //   { id: 1, label: "Portofolet", href: "/arketimet/portofolet" },
        //   { id: 2, label: "Total Portofolet", href: "/arketimet/total-portofolet" },
        // ],
      },
      {
        id: 2,
        title: "Special List",
        icon: ListTree,
        color: "text-white-500",
        href: "/special-list",
        roles: ["admin"],
      },
      {
        id: 3,
        title: "Raste te Mbyllura",
        icon: SquareX,
        color: "text-white-500",
        href: "/raste-closed",
      },
      {
        id: 4,
        title: "Specialiste",
        icon: Users,
        color: "text-white-500",
        href: "/specialiste",
      },
      {
        id: 5,
        title: "Kerkesa per aprovim",
        icon: GitPullRequest,
        color: "text-white-500",
        href: "/kerkesat",
      },
      {
        id: 6,
        title: "Logs",
        icon: FileClock,
        color: "text-white-500",
        href: "/logs",
        roles: ["admin"],
      },
    ],
    roles: ["admin", "user"],
  },
];
