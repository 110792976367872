import { useMutation } from "@tanstack/react-query";
import { Folder, Trash2Icon, Upload, UploadIcon } from "lucide-react";
import { DragEvent, useEffect, useState } from "react";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { COMMON_ENUMS } from "src/lib/enum";
import { uploadDokument } from "../../api";
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader } from "../ui/card";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import { Label } from "../ui/label";
import { useToast } from "../ui/use-toast";

type ButtonId = "kredie" | "hipoteke" | "ekzekutimi" | "permbarimi" | "pagese";

interface RastiDokumentUploadProps {
  rastId: string | undefined;
  onDocumentsUpdate: () => void;
  handleDokumentUpload?: () => void;
}
const RastiDokumentUpload = ({
  rastId,
  onDocumentsUpdate,
  handleDokumentUpload,
}: RastiDokumentUploadProps) => {
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [errorText, setErrorText] = useState("");
  const [buttonClicked, setButtonClicked] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const { toast } = useToast();

  const isButtonId = (id: string): id is ButtonId => {
    return [
      "kredie",
      "hipoteke",
      "ekzekutimi",
      "permbarimi",
      "pagese",
    ].includes(id);
  };
  const renderUploadButtons = [
    {
      id: "kredie",
      label: "Kontrata e Kredisë",
    },
    {
      id: "hipoteke",
      label: "Kontrata Hipoteke",
    },
    {
      id: "ekzekutimi",
      label: "Urdhër Ekzekutimi",
    },
    {
      id: "permbarimi",
      label: "Dokumente Përmbarimi",
    },
    {
      id: "pagese",
      label: "Marrëveshje Pagese",
    },
  ];

  useEffect(() => {
    if (selectedFile) {
      uploadFile();
    }
  }, [selectedFile]);

  const uploadFile = () => {
    setUploadStatus("uploading");
    setUploadProgress(0);
    const interval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          setErrorText("");
          if (rastId || buttonClicked) {
            const tipiFileMap: { [key in typeof buttonClicked]: COMMON_ENUMS } =
              {
                kredie: COMMON_ENUMS.KONTRATE_KREDIE,
                hipoteke: COMMON_ENUMS.DOKUMENT_TJETER,
                ekzekutimi: COMMON_ENUMS.URDHER_EKZEKUTIMI,
                permbarimi: COMMON_ENUMS.DOKUMENT_PERMBARIMI,
                pagese: COMMON_ENUMS.MARREVESHJE_PAGESE,
              };

            const tipiFile = tipiFileMap[buttonClicked];

            if (tipiFile && selectedFile) {
              uploadDokumentMutation.mutate({
                token,
                rastId: rastId ?? "",
                file: selectedFile,
                tipiFile,
              });
            }
          }
          return 100;
        } else {
          return prevProgress + 20;
        }
      });
    }, 500);
  };

  const uploadDokumentMutation = useMutation({
    mutationFn: uploadDokument,
    onSuccess: (data) => {
      onDocumentsUpdate();
      handleDokumentUpload?.();
      toast({
        variant: "success",
        title: "Success",
        description: "Dokumenti u ngarkua me sukses",
      });
      setButtonClicked("");
      setSelectedFile(null);
      setOpenDialog(false);
    },
    onError: (error) => {
      console.log(error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: error.message,
      });
      setUploadStatus("error");
      setErrorText(error.message);
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      setErrorText("");
      setSelectedFile(event.target.files[0]);
    }
    event.target.value = "";
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;

    if (droppedFiles && droppedFiles.length > 0) {
      const allowedExtensions = [".csv", ".xlsx", ".xls", ".pdf"];
      const isValidFile = Array.from<File>(droppedFiles).some((file: File) => {
        const fileName = file.name.toLowerCase();
        return allowedExtensions.some((ext) => fileName.endsWith(ext));
      });

      if (isValidFile) {
        setErrorText("");
        setSelectedFile(droppedFiles[0]);
      } else {
        setSelectedFile(droppedFiles[0]);
        setUploadStatus("error");
        setErrorText("Upload failed, please try again");
      }
    }
  };

  const getStatusColor = () => {
    switch (uploadStatus) {
      case "uploading":
        return "#737EDE";
      case "success":
        return "green";
      case "error":
        return "red";
      default:
        return "gray";
    }
  };

  const handleButtonClick = (id: string) => {
    if (isButtonId(id)) {
      setButtonClicked(id);
    }
  };

  const dialogOpenChange = () => {
    setOpenDialog(!openDialog);
    if (!openDialog) {
      uploadDokumentMutation.reset();
      setSelectedFile(null);
    }
  };
  return (
    <Card>
      <CardHeader className="text-lg font-semibold">Shto Dokumente</CardHeader>
      <CardContent className="grid flex-col gap-2">
        <Dialog open={openDialog} onOpenChange={dialogOpenChange}>
          <DialogTrigger className="grid w-full items-center gap-2">
            {renderUploadButtons.map((uploadButton) => (
              <Button
                variant="outline"
                key={uploadButton.id}
                className="flex h-auto items-center justify-between w-full bg-secondary text-white cursor-pointer"
                onClick={() => handleButtonClick(uploadButton.id)}
              >
                <Label>{uploadButton.label}</Label>
                <Upload size={16} />
              </Button>
            ))}
          </DialogTrigger>
          <DialogContent>
            <>
              <div
                className="border-dashed p-2 m-2 border border-slate-300 flex flex-col justify-center items-center gap-3"
                onDrop={handleDrop}
                onDragOver={(event) => event.preventDefault()}
              >
                <UploadIcon color="#737EDE" size={24} />
                <label htmlFor="documents">
                  <Button
                    variant="link"
                    className="text-secondary underline cursor-pointer"
                    onClick={() =>
                      document.getElementById("documents")?.click()
                    }
                  >
                    Click to upload
                  </Button>
                  <span>or drag and drop</span>
                </label>
                <input
                  id="documents"
                  type="file"
                  className="hidden"
                  accept="*"
                  onChange={handleFileChange}
                />
                <span>{"(Max. File size: 25 MB)"}</span>
              </div>
              {selectedFile && (
                <div
                  style={{ borderColor: getStatusColor() }}
                  className={`flex flex-col p-2 m-2 gap-4 border border-slate-300`}
                >
                  <div className="flex flex-row justify-between items-center">
                    <Folder
                      fill={getStatusColor()}
                      color={getStatusColor()}
                      size={20}
                    />
                    <span
                      style={{
                        inlineSize: "250px",
                        color: getStatusColor(),
                      }}
                      className={`text-center overflow-hidden break-all text-ellipsis`}
                    >
                      {selectedFile.name}
                    </span>
                    <Button
                      variant={"link"}
                      size="icon"
                      disabled={uploadStatus === "idle"}
                    >
                      <Trash2Icon size={20} color={getStatusColor()} />
                    </Button>
                  </div>
                  {selectedFile && (
                    <div className="flex flex-col gap-4">
                      <div
                        className="w-full h-1 rounded-md"
                        style={{
                          backgroundColor: "#e0e0e0",
                          position: "relative",
                        }}
                      >
                        <div
                          className="h-full rounded-md"
                          style={{
                            width: `${uploadProgress}%`,
                            backgroundColor: getStatusColor(),
                            transition: "width 0.5s ease",
                          }}
                        ></div>
                      </div>
                    </div>
                  )}
                  {!!errorText && (
                    <span style={{ color: "red" }} className="text-center">
                      {errorText}
                    </span>
                  )}
                </div>
              )}
            </>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default RastiDokumentUpload;
