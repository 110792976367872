import Keycloak from "keycloak-js";
import { COMMON_ENUMS } from "./lib/enum";

const keycloak = new Keycloak({
  url: `${COMMON_ENUMS.KEYCLOAK_BASE_URL}`,
  realm: "next-collection",
  clientId: "collection-app",
});

export default keycloak;
