import { useMutation } from "@tanstack/react-query";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { getLogs } from "../../api";
import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Separator } from "../ui/separator";
import { Spinner } from "../ui/spinner";

export interface LogsData {
  autor: string;
  data: string;
  id: number;
  pershkrimi: string;
}

const Logs = () => {
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");
  const [logsData, setLogsData] = useState<LogsData[]>([]);

  const getLogsMutation = useMutation({
    mutationFn: getLogs,
    onSuccess: (data) => {
      setLogsData(data.content);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    getLogsMutation.mutate({ token });
  }, []);

  if (getLogsMutation?.isPending) {
    return (
      <div className="px-5 w-1/2 justify-center mt-5">
        <Card>
          <CardHeader className="flex items-center justify-center">
            <CardTitle>Loading...</CardTitle>
          </CardHeader>
          <CardFooter className="flex items-center justify-center">
            <Spinner fill="black" />
          </CardFooter>
        </Card>
      </div>
    );
  }

  return (
    <div className="px-5 relative w-full mt-5 flex flex-col gap-3">
      <span className="text-secondaryDark font-semibold text-lg">Logs</span>
      <div className="flex flex-col gap-4 w-full">
        {logsData.length > 0 ? (
          logsData?.map((log) => (
            <Card key={log.id}>
              <CardHeader className="justify-start flex items-start p-3">
                <CardTitle className="text-sm flex flex-row justify-between items-center gap-3 w-full">
                  <div className=" flex flex-row items-center gap-2">
                    <span className="font-normal">{log.autor}</span>
                    <span className="font-normal">
                      {format(log.data, "EEE, MMM dd, H:mm")}
                    </span>
                    <Separator orientation="vertical" className="h-[30px]" />
                    <span>{log.pershkrimi}</span>
                  </div>
                </CardTitle>
              </CardHeader>
            </Card>
          ))
        ) : (
          <Card>
            <CardHeader className="flex justify-center items-center">
              <CardDescription>Nuk ka te dhena...</CardDescription>
            </CardHeader>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Logs;
