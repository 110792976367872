import { useMutation } from "@tanstack/react-query";
import { compareDesc, parseISO } from "date-fns";
import { Download, Folder, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { downloadFile, fshiDokumet, getDokumente } from "../../api";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Label } from "../ui/label";
import { Separator } from "../ui/separator";
import { Spinner } from "../ui/spinner";
import { useToast } from "../ui/use-toast";
import RastiDokumentUpload from "./RastiDokumentUpload";

interface RastiDokumenteProps {
  rastId: string | undefined;
  refreshKey: number;
}

export type Dokuments = {
  id: number;
  emri: string;
  tipiFile: string;
  uploadDate: string;
};

const RastiDokumente = ({
  rastId,

  refreshKey,
}: RastiDokumenteProps) => {
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");
  const [dokumentData, setDokumentData] = useState<Dokuments[]>([]);
  const { toast } = useToast();
  const [buttonClicked, setButtonClicked] = useState(0);
  const [open, setOpen] = useState(false);
  const [reset, setReset] = useState<number>(0);
  const [openDokumentUploadDialog, setOpenDokumentUploadDialog] =
    useState(false);

  const getDokumenteMutation = useMutation({
    mutationFn: getDokumente,
    onSuccess: (data) => {
      setDokumentData(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const downloadFileMutation = useMutation({
    mutationFn: downloadFile,
    onSuccess: (response) => {
      const disposition = response.headers["content-disposition"];
      const contentType = response.headers["content-type"];
      let filename = "downloaded-file";

      if (disposition && disposition.includes("attachment")) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }
      const mimeType = contentType || "application/octet-stream";

      const blob = new Blob([response.data], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
    onError: (error) => {
      console.error("Error downloading file:", error);
    },
  });

  const deleteFileMutation = useMutation({
    mutationFn: fshiDokumet,
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Success",
        description: "Dokumenti u fshi me sukses",
      });
      if (rastId && token) {
        getDokumenteMutation.mutate({ rastId, token });
      }
    },
    onError: (error) => {
      console.error("Error deleting file:", error);
    },
  });

  useEffect(() => {
    if (rastId && token) {
      getDokumenteMutation.mutate({ rastId, token });
    }
  }, [rastId, token, refreshKey, reset]);

  const renderContent = () => {
    if (getDokumenteMutation.isPending) {
      return <Spinner fill="black" />;
    } else if (dokumentData.length === 0) {
      return <Label className="font-normal">Nuk ka dokumente...</Label>;
    } else {
      return dokumentData
        .slice(0, 6)
        .sort((a, b) =>
          compareDesc(parseISO(a.uploadDate), parseISO(b.uploadDate))
        )
        .map((dokument, index) => (
          <div key={index}>
            <span>{dokument.tipiFile}</span>
            <Separator />
          </div>
        ));
    }
  };

  const handleDownload = (id: number) => {
    downloadFileMutation.mutate({ token, fileId: id });
  };
  const handleDelete = (id: number) => {
    deleteFileMutation.mutate({ token, fileId: id });
  };

  const handleDokumentUpload = () => {
    setReset((prev) => prev + 1);
    setOpenDokumentUploadDialog(false);
  };

  return (
    <Card>
      <CardHeader className="flex flex-row gap-2 items-center text-lg font-semibold">
        <Folder color="#737ede" fill="#737ede" />
        Dokumente
      </CardHeader>
      <CardContent className="grid flex-col gap-2 justify-center w-full">
        {renderContent()}
      </CardContent>
      <CardFooter className="justify-center items-center flex">
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogTrigger asChild>
            <Button
              disabled={dokumentData.length === 0}
              variant="outline"
              className="bg-secondary text-white w-full h-auto"
            >
              <Label>Shto dokumente te tjera</Label>
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-7xl w-full h-full flex flex-col ">
            <DialogHeader>
              <DialogTitle>Dokumente</DialogTitle>
            </DialogHeader>
            {getDokumenteMutation.isPending ? (
              <div className="w-1/2 h-full px-5 grid grid-cols-1 justify-center self-center items-center">
                <Card className="max-h-[150px]">
                  <CardHeader className="flex items-center justify-center">
                    <CardTitle>Loading...</CardTitle>
                  </CardHeader>
                  <CardFooter className="flex items-center justify-center">
                    <Spinner fill="black" />
                  </CardFooter>
                </Card>
              </div>
            ) : dokumentData.length > 0 ? (
              <div
                className={`w-full h-full px-5 grid grid-cols-1 ${
                  dokumentData.length > 0 &&
                  "gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3"
                } overflow-auto`}
              >
                {dokumentData.map((dokument, index) => (
                  <Card key={index} className="max-h-[150px]">
                    <CardHeader className="text-sm font-semibold">
                      {dokument.tipiFile}
                    </CardHeader>
                    <CardFooter className="flex justify-end gap-4 items-center">
                      <Button
                        variant="default"
                        onClick={() => {
                          handleDownload(dokument.id);
                          setButtonClicked(dokument.id);
                        }}
                        className="items-center flex justify-center"
                      >
                        {downloadFileMutation?.isPending &&
                        buttonClicked === dokument.id ? (
                          <Spinner fill="black" />
                        ) : (
                          <Download />
                        )}
                      </Button>
                      <Button
                        variant="destructive"
                        onClick={() => {
                          handleDelete(dokument.id);
                          setButtonClicked(dokument.id);
                        }}
                      >
                        {deleteFileMutation?.isPending &&
                        buttonClicked === dokument.id ? (
                          <Spinner fill="black" />
                        ) : (
                          <Trash2 />
                        )}
                      </Button>
                    </CardFooter>
                  </Card>
                ))}
              </div>
            ) : (
              <div className="w-1/2 h-full px-5 grid grid-cols-1 justify-center self-center items-center">
                <Card className="h-[100px] flex items-center justify-center">
                  <CardHeader className="flex items-center justify-center">
                    <CardTitle className="font-normal">
                      Nuk ka dokumente te ngarkuara...
                    </CardTitle>
                  </CardHeader>
                </Card>
              </div>
            )}
            <DialogFooter className="flex justify-center sm:justify-center items-center">
              <Dialog
                open={openDokumentUploadDialog}
                onOpenChange={setOpenDokumentUploadDialog}
              >
                <DialogTrigger className="w-full">
                  <Button
                    variant="outline"
                    className="bg-secondary text-white w-1/2"
                  >
                    Shto dokumente te tjera
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <RastiDokumentUpload
                    rastId={rastId}
                    onDocumentsUpdate={() => {}}
                    handleDokumentUpload={handleDokumentUpload}
                  />
                </DialogContent>
              </Dialog>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </CardFooter>
    </Card>
  );
};

export default RastiDokumente;
