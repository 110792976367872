import { useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export function Layout() {
  const scroll = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(()=>{
    scroll.current?.scrollTo({ top: 0, behavior: 'smooth' });
  },[navigate, location.pathname])

  return (
    <div className="flex justify-center w-full h-full overflow-auto -mt-3 z-50" ref={scroll}>
      <Outlet />
    </div>
  );
}
