import { ReactKeycloakProvider } from "@react-keycloak/web";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useState } from "react";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "./components/auth/PrivateRoutes";
import { Layout } from "./components/Layout";
import NotFoundPage from "./components/NotFoundPage";
import Portofol from "./components/portofoli/Portofol";
import PortofolListing from "./components/portofoli/portofol-listing/PortofolListing";
import Rasti from "./components/rasti/Rasti";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./components/ui/card";
import { Spinner } from "./components/ui/spinner";
import { Toaster } from "./components/ui/toaster";
import { useLocalStorage } from "./hooks/useLocalStorage";
import keycloak from "./Keycloak";
import { store } from "./redux/store";

import Arketimet from "./components/arketimet/Arketimet";
import Dorezanes from "./components/dorezanes/Dorezanes";
import Header from "./components/global/Header";
import Sidebar from "./components/global/SideBar";
import Kerkesat from "./components/kerkesat/Kerkesat";
import Klient from "./components/klient/Klient";
import Logs from "./components/logs/Logs";
import BulletPayment from "./components/plan-pagesat/BulletPayment";
import KestePeriodike from "./components/plan-pagesat/keste-periodike/KestePeriodike";
import PagesePjesshme from "./components/plan-pagesat/PagesePjesshme";
import PlanAmortizimi from "./components/plan-pagesat/plan-amortizimi/PlanAmortizimi";
import RakordimetFinanciare from "./components/rakordimet-financiare/RakordimetFinanciare";
import RasteClosed from "./components/raste-te-mbyllura/RasteClosed";
import SpecialList from "./components/special-list/SpecialList";
import Specialiste from "./components/specialist/Specialiste";
import SubjekteRastiDorezanes from "./components/subjekte-rasti/SubjekteRastiDorezanes";
import SubjekteRastiHipotekues from "./components/subjekte-rasti/SubjekteRastiHipotekues";

const queryClient = new QueryClient();

function App() {
  const initOptions = { pkceMethod: "S256" };
  const [loading, setLoading] = useState<boolean>(true);
  const { setItem: setNavbarItem } = useLocalStorage("selectedNavbarItem");
  const { setItem: setTokenItem, removeItem: removeTokenItem } =
    useLocalStorage("token");
  const [isSidebar, setIsSidebar] = useState(true);

  const handleOnEvent = async (event: any, error: any) => {
    if (event === "onAuthSuccess") {
      setLoading(false);
      setTokenItem(keycloak.token);
    }
    if (event === "onTokenExpired") {
      keycloak.logout();
      removeTokenItem("token");
      setNavbarItem({
        id: 1,
        title: "Te gjithe portofolet",
        color: "text-white-500",
        href: "/portofole",
        icon: {},
      });
    }
  };

  const loadingComponent = (
    <main className="flex flex-col mx-auto px-4 justify-center max-w-5xl w-full h-screen">
      <Card>
        <CardHeader className="items-center">
          <CardTitle>Keycloak is Running</CardTitle>
        </CardHeader>
        <CardContent className="space-y-2 flex flex-row justify-center">
          Redirecting. Please wait...
        </CardContent>
        {loading && (
          <CardFooter className="flex justify-center">
            <Spinner fill="black" />
          </CardFooter>
        )}
      </Card>
    </main>
  );

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      LoadingComponent={loadingComponent}
      onEvent={(event, error) => handleOnEvent(event, error)}
    >
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <div className="app">
            <Sidebar />
            <main className="content">
              <Header setIsSidebar={setIsSidebar} />
              <Routes>
                <Route element={<PrivateRoutes />}>
                  <Route path="/" element={<Layout />}>
                    <Route path="/portofole" element={<PortofolListing />} />
                    <Route
                      path="/portofole/portofol/:portofolId"
                      element={<Portofol />}
                    />
                    <Route path="/portofol/rasti/:rastId" element={<Rasti />} />
                    <Route path="/specialiste" element={<Specialiste />} />
                    <Route
                      path="/portofol/rasti/biznes/:debitorId"
                      element={<Dorezanes />}
                    />
                    <Route
                      path="/portofol/rasti/klient/:debitorId"
                      element={<Klient />}
                    />
                    <Route
                      path="/portofol/rasti/subjekte-rasti/dorezanes/:rastId"
                      element={<SubjekteRastiDorezanes />}
                    />
                    <Route
                      path="/portofol/rasti/subjekte-rasti/hipotekues/:rastId"
                      element={<SubjekteRastiHipotekues />}
                    />
                    <Route path="/raste-closed" element={<RasteClosed />} />
                    <Route path="/kerkesat" element={<Kerkesat />} />
                    <Route
                      path="/plan-pagesa/bullet-payment/:rastId"
                      element={<BulletPayment />}
                    />
                    <Route
                      path="/plan-pagesa/keste-periodike/:rastId"
                      element={<KestePeriodike />}
                    />
                    <Route
                      path="/plan-pagesa/pagese-pjesshme/:rastId"
                      element={<PagesePjesshme />}
                    />
                    <Route
                      path="/plan-pagesa/plan-amortizimi/:rastId"
                      element={<PlanAmortizimi />}
                    />
                    <Route path="/special-list" element={<SpecialList />} />
                    <Route
                      path="/rakordimet-financiare"
                      element={<RakordimetFinanciare />}
                    />
                    <Route path="/arketimet" element={<Arketimet />} />

                    <Route path="/logs" element={<Logs />} />
                  </Route>
                </Route>
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
              <Toaster />
            </main>
          </div>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </ReactKeycloakProvider>
  );
}

export default App;
