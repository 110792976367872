import { useEffect } from "react";
import { DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { Spinner } from "../ui/spinner";

const PortofoliDialog = (props: any) => {
  const step = props.step;

  useEffect(() => {
    if (!props.content) {
      props.onOpenChange();
    }
  }, [props.content]);

  return (
    <DialogContent
      step={props.step}
      className={`${
        step === 0 ? "sm:max-w-[425px]" : "h-screen overflow-y-auto"
      }`}
    >
      <DialogHeader>
        <DialogTitle>{props.title}</DialogTitle>
      </DialogHeader>
      {props.deleteRastMutation.isPending && props.step === 1 ? (
        <div className="w-full flex justify-center items-center">
          <Spinner fill="black" />
        </div>
      ) : (
        props.content
      )}
    </DialogContent>
  );
};

export default PortofoliDialog;
