import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NavItem } from "src/components/types";

interface UserState {
  selectedNavbarItem: NavItem;
}

const initialState: UserState = {
  selectedNavbarItem: {
    id: 0,
    title: "",
    href: "",
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addSelectedNavbarItem: (state, action: PayloadAction<NavItem>) => {
      state.selectedNavbarItem = action.payload;
    },
  },
});

export const { addSelectedNavbarItem } = userSlice.actions;

export default userSlice.reducer;
