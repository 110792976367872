import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "src/components/ui/dialog";
import { useToast } from "src/components/ui/use-toast";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { useAppDispatch } from "src/redux/hooks";
import {
  getFiltrat,
  getPortofolData,
  uploadBiznes,
  uploadPerson,
  uploadPortofol,
} from "../../../api";
import FileUploadButton from "./FileUploadButton";
import PortofolListingCards from "./PortofolListingCards";

const PortofolListing = () => {
  const { getItem } = useLocalStorage("token");
  const { setItem } = useLocalStorage("filter");
  const token = getItem("token");
  const [selectedPortofolFile, setSelectedPortofolFile] = useState<File>();
  const [selectedBiznesFile, setSelectedBiznesFile] = useState<File>();
  const [selectedPersonFile, setSelectedPersonFile] = useState<File>();
  const [portofole, setPortofole] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const { toast } = useToast();
  const [uploadStatus, setUploadStatus] = useState({
    portofol: false,
    biznes: false,
    person: false,
  });
  const dispatch = useAppDispatch();

  const portofolMutation = useMutation({
    mutationFn: getPortofolData,
    onSuccess: (data: any) => {
      setPortofole(data?.content);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const getFilterMutation = useMutation({
    mutationFn: getFiltrat,
    onSuccess: (data: any) => {
      setItem(data);
      // if (data) {
      //   dispatch(addFilterData(data));
      // }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    portofolMutation.mutate(token);
    getFilterMutation.mutate({ token });
  }, []);

  useEffect(() => {
    if (uploadStatus.portofol || uploadStatus.biznes || uploadStatus.person) {
      portofolMutation.mutate(token);
      //  setUploadStatus({ portofol: false, biznes: false, person: false });
    }
  }, [uploadStatus, token]);

  const handleSuccessToast = (description: string) => {
    return toast({
      variant: "success",
      title: "Success",
      description: description,
    });
  };

  const handleErrorToast = (description: string) => {
    return toast({
      variant: "destructive",
      title: "Error",
      description: description,
    });
  };

  const uploadPortofolMutation = useMutation({
    mutationFn: uploadPortofol,
    onSuccess: () => {
      handleSuccessToast("Portofol uploaded successfully");
      setUploadStatus((prev) => ({ ...prev, portofol: true }));
      setIsDisabled(false);
    },
    onError: (error: any) => {
      handleErrorToast(error.message);
    },
  });

  const uploadPersonMutation = useMutation({
    mutationFn: uploadPerson,
    onSuccess: () => {
      handleSuccessToast("Person uploaded successfully");
      setUploadStatus((prev) => ({ ...prev, person: true }));
      if (uploadStatus.portofol && uploadStatus.biznes) {
        setOpen(!open);
      }
    },
    onError: (error: any) => {
      handleErrorToast(error.message);
    },
  });

  const uploadBiznesMutation = useMutation({
    mutationFn: uploadBiznes,
    onSuccess: () => {
      handleSuccessToast("Biznes uploaded successfully");
      setUploadStatus((prev) => ({ ...prev, biznes: true }));
    },
    onError: (error: any) => {
      handleErrorToast(error.message);
    },
  });

  const handleProtofolFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    nameOfTheFile: string
  ) => {
    switch (nameOfTheFile) {
      case "portofol":
        if (event.target.files?.[0]) {
          setSelectedPortofolFile(event.target.files[0]);
          const body = {
            token,
            portofol: event.target.files[0],
          };
          uploadPortofolMutation.mutate(body);
        }
        event.target.value = "";
        break;
      case "person":
        if (event.target.files?.[0]) {
          setSelectedPersonFile(event.target.files[0]);
          const body = {
            token,
            person: event.target.files[0],
          };
          uploadPersonMutation.mutate(body);
        }
        event.target.value = "";

        break;

      case "biznes":
        if (event.target.files?.[0]) {
          setSelectedBiznesFile(event.target.files[0]);
          const body = {
            token,
            biznes: event.target.files[0],
          };
          uploadBiznesMutation.mutate(body);
        }
        event.target.value = "";

        break;
    }
  };

  const handleOpenChange = () => {
    setOpen(!open);
    if (!open) {
      setSelectedPortofolFile(undefined);
      setSelectedPersonFile(undefined);
      setSelectedBiznesFile(undefined);
      setUploadStatus({ portofol: false, biznes: false, person: false });
      setIsDisabled(true);
      uploadPersonMutation.reset();
    }
  };

  return (
    <div className="px-5 relative w-full z-10 flex flex-col gap-3">
      <PortofolListingCards
        portofole={portofole}
        portofolMutation={portofolMutation}
      />
      <Dialog open={open} onOpenChange={handleOpenChange}>
        <DialogTrigger asChild>
          {!portofolMutation?.isPending && (
            <Button
              variant="default"
              className="items-center w-[300px]"
              // onClick={() => setOpen(true)}
            >
              Shto portofol
            </Button>
          )}
        </DialogTrigger>
        <DialogContent
          className="sm:max-w-[425px]"
          onPointerDownOutside={(e) => e.preventDefault()}
        >
          <DialogHeader>Krijo portofol</DialogHeader>
          <FileUploadButton
            id="portofol"
            isPending={uploadPortofolMutation.isPending}
            selectedFile={selectedPortofolFile}
            handleFileChange={handleProtofolFileChange}
            label="Portofol"
          />
          <FileUploadButton
            id="biznes"
            isPending={uploadBiznesMutation.isPending}
            selectedFile={selectedBiznesFile}
            handleFileChange={handleProtofolFileChange}
            label="Biznes"
            isDisabled={isDisabled}
          />
          <FileUploadButton
            id="person"
            isPending={uploadPersonMutation.isPending}
            selectedFile={selectedPersonFile}
            handleFileChange={handleProtofolFileChange}
            label="Person"
            isDisabled={isDisabled}
            isError={uploadPersonMutation.isError}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PortofolListing;
