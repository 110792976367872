import { useKeycloak } from "@react-keycloak/web";
import { useMutation } from "@tanstack/react-query";
import { Folder, Trash2Icon, UploadIcon } from "lucide-react";
import { DragEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isAdmin } from "src/lib/utils.ts";
import {
  fshiPortofolin,
  fshiRastin,
  getPortofolDetails,
  uploadRast,
} from "../../api/index.ts";
import { useLocalStorage } from "../../hooks/useLocalStorage.ts";
import { Portofoli, Raste } from "../types/tabela-portofol.ts";
import { Button } from "../ui/button.tsx";
import { Card, CardFooter, CardHeader, CardTitle } from "../ui/card.tsx";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog.tsx";
import { Spinner } from "../ui/spinner.tsx";
import { useToast } from "../ui/use-toast.ts";
import PortofolCards from "./PortofolCards.tsx";
import { columns } from "./portofoli-dialog-table/Columns.tsx";
import { DataTable as RastiDataTable } from "./portofoli-dialog-table/DataTable";
import { columns as portofolColumn } from "./portofoli-table/Columns.tsx";
import { DataTable } from "./portofoli-table/DataTable.tsx";
import PortofoliDialog from "./PortofoliDialog.tsx";

const Portofol = () => {
  const { keycloak } = useKeycloak();
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");
  const filterData = getItem("filter");
  let { portofolId } = useParams();
  const [portofolDetails, setPortofolDetails] = useState<Portofoli>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [errorText, setErrorText] = useState("");
  const [step, setStep] = useState<keyof typeof dialogSteps>(0);
  const [rastFileData, setRastFileData] = useState<Raste[]>([]);
  const [open, setOpen] = useState(false);
  const { toast } = useToast();
  const [buttonClicked, setButtonClicked] = useState(0);
  const navigate = useNavigate();
  const [deletePortofolOpen, setDeletePortofolOpen] = useState(false);

  const transformData = (data: any) => {
    return data.map((item: any) => {
      return {
        ...item,
        segmenti: filterData.segmenti[item.segmenti] || item.segmenti,
        klasifikimKredie:
          filterData.klasifikimKredie[item.klasifikimKredie] ||
          item.klasifikimKredie,
        ekSipasMaturimit:
          filterData.ekSipasMaturimit[item.ekSipasMaturimit] ||
          item.ekSipasMaturimit,
        situateLigjore:
          filterData.situateLigjore[item.situateLigjore] || item.situateLigjore,
        statusVerbal:
          filterData.statusVerbal[item.statusVerbal] || item.statusVerbal,
        fazaPermbarimit:
          filterData.fazaPermbarimit[item.fazaPermbarimit] ||
          item.fazaPermbarimit,
      };
    });
  };

  const portofolDetailsMutation = useMutation({
    mutationFn: getPortofolDetails,
    onSuccess: (data: any) => {
      const transformedData = transformData(data.raste);
      setPortofolDetails({
        ...data,
        raste: transformedData,
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const deletePortofolMutation = useMutation({
    mutationFn: fshiPortofolin,
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Portofoli u fshi me sukses!",
      });
      navigate("/portofole", { replace: true });
    },
    onError: (error) => {
      console.log(error);
      toast({
        variant: "destructive",
        title: error.message,
      });
    },
  });

  const handleDeletePortofol = () => {
    if (portofolId) {
      const body = {
        token,
        portofolId: parseInt(portofolId),
      };
      deletePortofolMutation.mutate(body);
    }
  };

  const handleSetStep = (step: keyof typeof dialogSteps) => setStep(step);

  const uploadRastMutation = useMutation({
    mutationFn: uploadRast,
    onSuccess: (data: any) => {
      const transformedData = transformData(data);

      setRastFileData(transformedData);
      handleSetStep(1);
    },
    onError: (error: any) => {
      setErrorText(error.response.data.message);
      setUploadStatus("error");
    },
  });

  const deleteRastMutation = useMutation({
    mutationFn: fshiRastin,
    onSuccess: (_, { rastId }) => {
      toast({
        variant: "success",
        title: "Rasti u fshi me sukses!",
      });
      setRastFileData((prevData) =>
        prevData.filter((rast) => rast.id !== rastId)
      );
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        description: error.message,
      });
    },
  });

  useEffect(() => {
    if (portofolId) {
      const body = {
        token,
        portofolId,
      };
      portofolDetailsMutation.mutate(body);
    }
  }, [token, portofolId]);

  useEffect(() => {
    if (selectedFile) {
      uploadFile();
    }
  }, [selectedFile]);

  useEffect(() => {
    if (!open && portofolId) {
      const body = {
        token,
        portofolId,
      };
      portofolDetailsMutation.mutate(body);
    }
  }, [open]);

  const maxFileSize = 25 * 1024 * 1024;

  const uploadFile = () => {
    setUploadStatus("uploading");
    setUploadProgress(0);

    const interval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          setErrorText("");
          if (selectedFile) {
            portofolId = portofolId ?? "";
            const body = {
              token,
              rasti: selectedFile,
              portofolId,
            };
            uploadRastMutation.mutate(body);
          }
          return 100;
        } else {
          return prevProgress + 20;
        }
      });
    }, 500);
  };

  const getStatusColor = () => {
    switch (uploadStatus) {
      case "uploading":
        return "#737EDE";
      case "success":
        return "green";
      case "error":
        return "red";
      default:
        return "gray";
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      setErrorText("");
      setSelectedFile(event.target.files[0]);
    }
    event.target.value = "";
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;

    if (droppedFiles && droppedFiles.length > 0) {
      const allowedExtensions = [".csv", ".xlsx", ".xls"];
      const isValidFile = Array.from<File>(droppedFiles).some((file: File) => {
        const fileName = file.name.toLowerCase();
        return allowedExtensions.some((ext) => fileName.endsWith(ext));
      });

      if (isValidFile) {
        setErrorText("");
        setSelectedFile(droppedFiles[0]);
      } else {
        setSelectedFile(droppedFiles[0]);
        setUploadStatus("error");
        setErrorText("Upload failed, please try again");
      }
    }
  };

  const handleDeleteRow = (id: number) => {
    setButtonClicked(id);
    deleteRastMutation.mutate({ token, rastId: id });
  };

  const onOpenChange = () => {
    setOpen(!open);
    setSelectedFile(null);
    handleSetStep(0);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedFile(null);
    handleSetStep(0);
    if (portofolId) {
      const body = {
        token,
        portofolId,
      };
      portofolDetailsMutation.mutate(body);
    }
  };

  const dialogSteps = {
    0: {
      title: "Shto Rast",
      content: (
        <>
          <div
            className="border-dashed p-2 m-2 border border-slate-300 flex flex-col justify-center items-center gap-3"
            onDrop={handleDrop}
            onDragOver={(event) => event.preventDefault()}
          >
            <UploadIcon color="#737EDE" size={24} />
            <label htmlFor="documents">
              <Button
                variant="link"
                className="text-secondary underline cursor-pointer"
                onClick={() => document.getElementById("documents")?.click()}
              >
                Click to upload
              </Button>
              <span>or drag and drop</span>
            </label>
            <input
              id="documents"
              type="file"
              className="hidden"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={handleFileChange}
            />
            <span>{"(Max. File size: 25 MB)"}</span>
          </div>
          {selectedFile && (
            <div
              style={{ borderColor: getStatusColor() }}
              className={`flex flex-col p-2 m-2 gap-4 border border-slate-300`}
            >
              <div className="flex flex-row justify-between items-center">
                <Folder
                  fill={getStatusColor()}
                  color={getStatusColor()}
                  size={20}
                />
                <span
                  style={{ inlineSize: "250px", color: getStatusColor() }}
                  className={`text-center overflow-hidden break-all text-ellipsis`}
                >
                  {selectedFile.name}
                </span>
                <Button
                  variant={"link"}
                  size="icon"
                  disabled={uploadStatus === "idle"}
                >
                  <Trash2Icon size={20} color={getStatusColor()} />
                </Button>
              </div>
              {selectedFile && (
                <div className="flex flex-col gap-4">
                  <div
                    className="w-full h-1 rounded-md"
                    style={{
                      backgroundColor: "#e0e0e0",
                      position: "relative",
                    }}
                  >
                    <div
                      className="h-full rounded-md"
                      style={{
                        width: `${uploadProgress}%`,
                        backgroundColor: getStatusColor(),
                        transition: "width 0.5s ease",
                      }}
                    ></div>
                  </div>
                </div>
              )}
              {!!errorText && (
                <span style={{ color: "red" }} className="text-center">
                  {errorText}
                </span>
              )}
            </div>
          )}
        </>
      ),
    },
    1: {
      title: "Document Preview",
      content: rastFileData?.length > 0 && (
        <RastiDataTable
          data={rastFileData}
          columns={columns}
          handleDeleteRow={handleDeleteRow}
          deleteRastMutation={deleteRastMutation}
          buttonClicked={buttonClicked}
          onOpenChange={handleCloseDialog}
        />
      ),
    },
  };

  if (portofolDetailsMutation?.isPending) {
    return (
      <div className="px-5 w-1/2 justify-center mt-5">
        <Card>
          <CardHeader className="flex items-center justify-center">
            <CardTitle>Loading...</CardTitle>
          </CardHeader>
          <CardFooter className="flex items-center justify-center">
            <Spinner fill="black" />
          </CardFooter>
        </Card>
      </div>
    );
  }

  return (
    <div className="px-5 relative w-full h-screen flex flex-col gap-4">
      {isAdmin(keycloak) && (
        <div className="px-5 relative w-full">
          {portofolDetails && (
            <PortofolCards portofolDetails={portofolDetails} />
          )}
        </div>
      )}
      {portofolDetails?.raste && (
        <DataTable data={portofolDetails.raste} columns={portofolColumn} />
      )}
      {isAdmin(keycloak) && (
        <div className="flex flex-row gap-4">
          <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogTrigger asChild>
              <Button variant="default" className="items-center w-[200px]">
                Shto Rast
              </Button>
            </DialogTrigger>
            <PortofoliDialog
              title={dialogSteps[step].title}
              content={dialogSteps[step].content}
              step={step}
              onOpenChange={handleCloseDialog}
              deleteRastMutation={deleteRastMutation}
            />
          </Dialog>
          <Dialog
            open={deletePortofolOpen}
            onOpenChange={setDeletePortofolOpen}
          >
            <DialogTrigger asChild>
              <Button
                variant="destructive"
                className="flex flex-row gap-3 items-center w-[200px]"
              >
                Fshi Portofolin <Trash2Icon />
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>
                  Je i sigurt qe do te fshish portofolin?
                </DialogTitle>
              </DialogHeader>
              <DialogFooter>
                <Button
                  variant="default"
                  onClick={() => {
                    setDeletePortofolOpen(!deletePortofolOpen);
                  }}
                >
                  Mbyll
                </Button>
                <Button variant="destructive" onClick={handleDeletePortofol}>
                  {deletePortofolMutation.isPending ? (
                    <Spinner fill="black" />
                  ) : (
                    "Fshij"
                  )}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default Portofol;
