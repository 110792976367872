import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { fshiBiznesin, fshiPersonin, getRastDorezanes } from "../../api";
import { PersonList } from "../types/tabela-portofol";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Spinner } from "../ui/spinner";
import { useToast } from "../ui/use-toast";
import { columns as columnsSubjekteRasti } from "./subjekt-rasti-biznes/rasti-biznes-table/Columns";
import columnsPerson from "./subjekt-rasti-biznes/rasti-biznes-table/ColumnsPerson";
import { DataTableBiznes } from "./subjekt-rasti-biznes/rasti-biznes-table/DataTableBiznes";
import { DataTablePerson } from "./subjekt-rasti-biznes/rasti-biznes-table/DataTablePerson";

const SubjekteRastiDorezanes = () => {
  let { rastId } = useParams();
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");
  const { toast } = useToast();
  const [subjektePersonData, setSubjektePersonData] = useState<PersonList[]>(
    []
  );
  const [subjekteBiznesData, setSubjekteBiznesData] = useState<[]>([]);

  const subjekteRastiPersonMutation = useMutation({
    mutationFn: getRastDorezanes,
    onSuccess: (data) => {
      setSubjektePersonData(data.persona);
      setSubjekteBiznesData(data.shoqeri);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (rastId) {
      subjekteRastiPersonMutation.mutate({ token, rastId });
    }
  }, [rastId]);

  const deleteBiznesMutation = useMutation({
    mutationFn: fshiBiznesin,
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Biznesi u fshi me sukses",
        description: "Biznesi u fshi me sukses",
      });
      if (rastId) {
        subjekteRastiPersonMutation.mutate({ token, rastId });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const deletePersonMutation = useMutation({
    mutationFn: fshiPersonin,
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Personi u fshi me sukses",
        description: "Personi u fshi me sukses",
      });
      if (rastId) {
        subjekteRastiPersonMutation.mutate({ token, rastId });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDeleteBiznesRow = (id: number) => {
    deleteBiznesMutation.mutate({ token, dorezanesId: id });
  };

  const handleDeletePersonRow = (id: number) => {
    deletePersonMutation.mutate({ token, personId: id });
  };

  if (subjekteRastiPersonMutation.isPending) {
    return (
      <div className="px-5 w-1/2 justify-center mt-5">
        <Card>
          <CardHeader className="flex items-center justify-center">
            <CardTitle>Loading...</CardTitle>
          </CardHeader>
          <CardFooter className="flex items-center justify-center">
            <Spinner fill="black" />
          </CardFooter>
        </Card>
      </div>
    );
  }

  return (
    <div className="h-screen relativev px-5 w-full">
      <Card>
        <CardHeader className="justify-start flex items-start">
          <CardTitle className="text-sm">
            Rasti {rastId} -
            <span className="text-secondary">
              Shoqëri Dorëzanëse/Hipotekues
            </span>
          </CardTitle>
          <CardContent>
            Tipi i Subjektit : <span className="font-semibold">Dorezanes</span>
          </CardContent>
        </CardHeader>
      </Card>
      <div className="justify-start mt-5 flex flex-col gap-4">
        {subjektePersonData && (
          <DataTablePerson
            data={subjektePersonData}
            columns={columnsPerson}
            handleDeleteRow={handleDeletePersonRow}
          />
        )}
        {subjekteBiznesData && (
          <DataTableBiznes
            data={subjekteBiznesData}
            columns={columnsSubjekteRasti}
            handleDeleteRow={handleDeleteBiznesRow}
          />
        )}
      </div>
    </div>
  );
};

export default SubjekteRastiDorezanes;
