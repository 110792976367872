import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Separator } from "../ui/separator";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { getPersonInfo } from "../../api";
import { Spinner } from "../ui/spinner";

const Klient = () => {
  const { debitorId } = useParams();
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");
  const [personInfo, setPersonInfo] = useState<any>();

  const getPersonMutation = useMutation({
    mutationFn: getPersonInfo,
    onSuccess: (data) => {
      setPersonInfo(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (token && debitorId) {
      const body = { token, klientId: debitorId };
      getPersonMutation.mutate(body);
    }
  }, [token, debitorId]);

  if (getPersonMutation.isPending) {
    return (
      <div className="px-5 w-1/2 justify-center mt-5">
        <Card>
          <CardHeader className="flex items-center justify-center">
            <CardTitle>Loading...</CardTitle>
          </CardHeader>
          <CardFooter className="flex items-center justify-center">
            <Spinner fill="black" />
          </CardFooter>
        </Card>
      </div>
    );
  }

  return (
    <div className="h-screen w-full relative px-5">
      <Card>
        <CardHeader className="justify-start flex items-start">
          <CardTitle className="text-sm">
            Klient {debitorId} -
            <span className="text-secondary">{`${personInfo?.emri} ${personInfo?.mbiemer}`}</span>
          </CardTitle>
        </CardHeader>
      </Card>
      <div className="mt-5 grid grid-cols-4 gap-3">
        <div className="col-span-1 sm:col-span-2 lg:col-span-2">
          <Card>
            <CardContent className=" p-3 m-3 grid flex-col gap-2">
              <div className="flex flex-row justify-between">
                <span>EMRI:</span>
                <span>{personInfo?.emri}</span>
              </div>
              <Separator />
              <div className="flex flex-row justify-between items-center">
                <span>MBIEMRI:</span>
                <span>{personInfo?.mbiemer}</span>
              </div>
              <Separator />
              <div className="flex flex-row justify-between items-center">
                <span>ATESIA:</span>
                <span>{personInfo?.atesia}</span>
              </div>
              <Separator />
              <div className="flex flex-row justify-between items-center">
                <span>DATELINDJA:</span>
                <span>{personInfo?.datelindje}</span>
              </div>
              <Separator />
              <div className="flex flex-row justify-between">
                <span>ADRESA:</span>
                <span>{personInfo?.adrese?.street}</span>
              </div>
              <Separator />
              <div className="flex flex-row justify-between">
                <span>QYTETI:</span>
                <span>{personInfo?.adrese?.city}</span>
              </div>
              <Separator />
              <div className="flex flex-row justify-between">
                <span>SHTETI:</span>
                <span>{personInfo?.adrese?.country}</span>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="col-span-1 sm:col-span-2 lg:col-span-2">
          <Card>
            <CardContent className=" p-3 m-3 flex flex-row gap-2 justify-evenly items-center">
              <div className="flex flex-col items-center">
                <span>NUMER TELEFONI:</span>
                {personInfo?.tel?.map((nr: string, idx: number) => (
                  <span key={idx}>{nr}</span>
                ))}
              </div>
              <Separator orientation="vertical" className="h-[100px]" />

              <div className="flex flex-col items-center">
                <span>EMAIL:</span>
                <span>{personInfo?.email}</span>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Klient;
