import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";
import { Label } from "../ui/label";

interface RastiPaymentCardsProps {
  rastId: string | undefined;
}

const RastiPaymentCards = ({ rastId }: RastiPaymentCardsProps) => {
  const navigate = useNavigate();

  const paymentMethods = [
    {
      title: "Menyra e Pageses",
      label: "Me keste ose plan amortizimi",
      buttonPath: `/plan-pagesa/plan-amortizimi/${rastId}`,
    },
    {
      title: "Menyra e Pageses",
      label: "Bullet payment",
      buttonPath: `/plan-pagesa/bullet-payment/${rastId}`,
    },
    {
      title: "Menyra e Pageses",
      label: "Me keste periodike",
      buttonPath: `/plan-pagesa/keste-periodike/${rastId}`,
    },
    {
      title: "Menyra e Pageses",
      label: "Pagesa te pjesshme te detyrimit",
      buttonPath: `/plan-pagesa/pagese-pjesshme/${rastId}`,
    },
  ];
  return paymentMethods.map((method, index) => (
    <Card key={index}>
      <CardHeader className="text-lg font-semibold">{method.title}</CardHeader>
      <CardContent>
        <Label />
      </CardContent>
      <CardFooter className="  p-3 flex items-center flex-row justify-between">
        <Label>{method.label}</Label>
        <Button
          variant="outline"
          className="bg-[#E5E4FF]"
          onClick={() => navigate(method.buttonPath)}
        >
          <ChevronRight color="#7680DF" />
        </Button>
      </CardFooter>
    </Card>
  ));
};

export default RastiPaymentCards;
