import { useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";

const PortofolListingCards = ({ portofole, portofolMutation }: any) => {
  const navigate = useNavigate();

  if (portofolMutation?.isPending) {
    return (
      <Card>
        <CardHeader className="justify-center items-center">
          <CardTitle className="text-sm">Loading...</CardTitle>
        </CardHeader>
      </Card>
    );
  }

  if (portofole?.length === 0) {
    return (
      <Card>
        <CardHeader className="justify-center items-center">
          <CardTitle className="text-sm">Nuk ka te dhena</CardTitle>
        </CardHeader>
      </Card>
    );
  }

  return (
    <div className="grid grid-cols-1 w-full gap-4">
      {portofole?.length > 0 &&
        portofole.map((portofol: any, index: any) => {
          return (
            <Card key={index}>
              <CardHeader>
                <CardTitle className="text-sm">{portofol.name}</CardTitle>
              </CardHeader>
              <div className="flex flex-col sm:flex-row justify-around row-span-1 sm:row-span-2 lg:row-span-3 gap-4">
                <CardContent className="w-full sm:w-1/3 flex gap-3 items-center justify-center">
                  <p className="text-sm">VLERA TOTALE</p>
                  <p className="font-semibold text-sm">
                    {portofol.vleraTotale}
                  </p>
                </CardContent>
                <CardContent className="w-full sm:w-1/3 flex gap-3 items-center justify-center">
                  <p className="text-sm">VLERA E PORTOFOLIT</p>
                  <p className="font-semibold text-sm">
                    {portofol.vleraPortofolit}
                  </p>
                </CardContent>
                <CardContent className="w-full sm:w-1/3 flex gap-3 items-center justify-center">
                  <p className="text-sm">ARKËTIME (DERI ME TANI)</p>
                  <p className="font-semibold text-sm">{portofol.arketim}</p>
                </CardContent>
                <CardContent className=" w-full sm:w-1/3 flex gap-3 items-center justify-center">
                  <Button
                    variant="default"
                    onClick={() =>
                      navigate(`/portofole/portofol/${portofol.id}`)
                    }
                  >
                    Me Shume
                  </Button>
                </CardContent>
              </div>
            </Card>
          );
        })}
    </div>
  );
};

export default PortofolListingCards;
