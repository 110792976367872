import { Row } from "@tanstack/react-table";

import { Trash2 } from "lucide-react";
import { useEffect, useState } from "react";

import { Raste } from "src/components/types/tabela-portofol";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import { Button } from "../../ui/button";
interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  handleDeleteRow: (id: number) => void;
  deletionSuccess: boolean;
}

export function DataTableRowActions<TData>({
  row,
  handleDeleteRow,
  deletionSuccess,
}: DataTableRowActionsProps<TData>) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (deletionSuccess) {
      setOpen(false);
    }
  }, [deletionSuccess]);

  const onOpenChange = () => {
    setOpen(!open);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger>
        <Button variant="destructive" className="py-2 my-2 mx-2">
          <Trash2 color="white" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] border border-secondary">
        <DialogHeader>
          <DialogTitle>Konfirmo fshirjen e rastit</DialogTitle>
        </DialogHeader>
        <DialogFooter>
          <Button
            variant="default"
            onClick={() => {
              setOpen(!open);
            }}
          >
            Mbyll
          </Button>
          <Button
            variant="destructive"
            onClick={() => handleDeleteRow((row.original as Raste).id)}
          >
            Fshij
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
