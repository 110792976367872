import { useKeycloak } from "@react-keycloak/web";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch } from "src/redux/hooks";
import { addSelectedNavbarItem } from "src/redux/slices/UserSlice";
import { cn, isAdmin } from "../../lib/utils";
import { type NavItem } from "../types/index";
import { buttonVariants } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Separator } from "../ui/separator";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import useCustomHistory from "src/hooks/useCustomHistory";

interface SideNavProps {
  items: NavItem[];
  setIsOpen?: (open: boolean) => void;
  isOpen?: boolean;
}

export function SideNav({ items, setIsOpen, isOpen }: SideNavProps) {
  const { setItem, getItem } = useLocalStorage("selectedNavbarItem");
  const selectedNavItem = getItem("selectedNavbarItem");
  const { history } = useCustomHistory();
  const dispatch = useAppDispatch();
  const { keycloak } = useKeycloak();
  const [open, setOpen] = useState(false);
  const admin = isAdmin(keycloak);

  const [selectedItem, setSelectedItem] = useState<NavItem>(
    selectedNavItem || {
      id: 1,
      title: "Te gjithe portofolet",
      color: "text-white-500",
      href: "/portofole",
      icon: {},
    }
  );

  useEffect(() => {
    if (selectedNavItem) {
      setSelectedItem(selectedNavItem);
    }
  }, [history]);

  useEffect(() => {
    const { icon, ...rest } = selectedItem;
    dispatch(addSelectedNavbarItem(rest));
    setItem(selectedItem);
  }, [selectedItem]);

  const filteredItems = items.filter(
    (item) => !item.roles || item.roles.includes(admin ? "admin" : "user")
  );

  return (
    <nav className={`space-y-2 overflow-y-auto`}>
      {filteredItems.map((item) => (
        <div
          key={item.id}
          className={`flex flex-col gap-2 ${!isOpen && "items-center"}`}
        >
          <Separator />
          <span className="text-semibold px-1 font-medium mt-2" key={item.id}>
            {item.title}
          </span>
          {item.children
            ? item.children
                .filter(
                  (child) =>
                    !child.roles ||
                    child.roles.includes(admin ? "admin" : "user")
                )
                .map((child) => {
                  return child.dropDownItems ? (
                    <DropdownMenu
                      open={open}
                      onOpenChange={setOpen}
                      key={child.id}
                    >
                      <DropdownMenuTrigger asChild key={child.id}>
                        <Link
                          key={child.id}
                          to={child.href}
                          className={cn(
                            buttonVariants({ variant: "ghost" }),
                            "group relative flex h-12 justify-start",
                            selectedItem.title === child.title &&
                              "bg-[#A4ADFD] text-foreground hover:bg-[#A4ADFD]"
                          )}
                        >
                          {child.icon ? (
                            <child.icon
                              className={cn("h-5 w-5", child.color)}
                            />
                          ) : null}
                          {isOpen && (
                            <span
                              className={cn(
                                "absolute left-12 text-sm duration-200 flex flex-row gap-5",
                                !isOpen
                              )}
                            >
                              {child.title}
                              {open ? (
                                <ChevronUpIcon color="black" />
                              ) : (
                                <ChevronDownIcon color="black" />
                              )}
                            </span>
                          )}
                        </Link>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="w-[200px] bg-secondaryLight">
                        {child.dropDownItems.map((dropDownItem) => (
                          <DropdownMenuItem
                            key={dropDownItem.id}
                            onClick={() => {
                              if (setIsOpen) setIsOpen(false);
                              setSelectedItem(child);
                              setOpen(false);
                            }}
                          >
                            <Link
                              key={dropDownItem.id}
                              to={dropDownItem.href}
                              className="font-semibold"
                            >
                              {dropDownItem.label}
                            </Link>
                          </DropdownMenuItem>
                        ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  ) : (
                    <Link
                      key={child.id}
                      to={child.href}
                      onClick={() => {
                        if (setIsOpen) setIsOpen(false);
                        setSelectedItem(child);
                      }}
                      className={cn(
                        buttonVariants({ variant: "ghost" }),
                        "group relative flex h-12 justify-start",
                        selectedItem.title === child.title &&
                          "bg-[#A4ADFD] text-foreground hover:bg-[#A4ADFD]"
                      )}
                    >
                      {child.icon ? (
                        <child.icon className={cn("h-5 w-5", child.color)} />
                      ) : null}
                      {isOpen && (
                        <span
                          className={cn(
                            "absolute left-12 text-sm duration-200",
                            !isOpen
                          )}
                        >
                          {child.title}
                        </span>
                      )}
                    </Link>
                  );
                })
            : null}
        </div>
      ))}
    </nav>
  );
}
