import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "./DataTableColumnHeader";

interface ResponseData {
  [key: string]: any;
}

export const generateColumns = (data: ResponseData): ColumnDef<any>[] => {
  const columns: ColumnDef<any>[] = Object.keys(data).map((key) => {
    return {
      id: key,
      accessorKey: key,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={key} />
      ),
      // You can customize the cell rendering based on your needs
      // cell: ({ row }) => <div>{row.getValue(key)}</div>,
    };
  });

  // Optionally add static columns, such as action buttons
  //   columns.push({
  //     id: "actions",
  //     // cell: ({ row }) => <DataTableRowActions row={row} />,
  //   });

  return columns;
};
