import axios, { AxiosResponse } from "axios";
import { COMMON_ENUMS } from "../lib/enum";

// Portofol API Functions
export async function getPortofolData(accessToken: string) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/portofol`,
    config
  );
  return data;
}

export async function getPortofolDetails(body: {
  token: string;
  portofolId: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/portofol/${body.portofolId}`,
    config
  );
  return data;
}

export async function uploadPortofol(body: { token: string; portofol: File }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const formData = new FormData();
  formData.append("file", body.portofol);

  const response = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/portofol/ngarko`,
    formData,
    config
  );

  return response.data;
}

export async function fshiPortofolin(body: {
  token: string;
  portofolId: number;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.delete(
    `${COMMON_ENUMS.BASE_API}/api/portofol/delete/${body.portofolId}`,
    config
  );
  return data;
}
// Biznes API Functions
export async function uploadBiznes(body: { token: string; biznes: File }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const formData = new FormData();
  formData.append("file", body.biznes);

  const response = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/biznes/ngarko`,
    formData,
    config
  );

  return response.data;
}

export async function getDebitorDetails(body: {
  token: string;
  debitorId: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/biznes/${body.debitorId}`,
    config
  );
  return data;
}

export async function subjekteRastiBiznes(body: {
  token: string;
  rastId: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/biznes/subjekte-rasti/${body.rastId}`,
    config
  );
  return data;
}

export async function fshiBiznesin(body: {
  token: string;
  dorezanesId: number;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.delete(
    `${COMMON_ENUMS.BASE_API}/api/biznes/${body.dorezanesId}`,
    config
  );
  return data;
}

// Person API Functions
export async function fshiPersonin(body: { token: string; personId: number }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.delete(
    `${COMMON_ENUMS.BASE_API}/api/person/delete/${body.personId}`,
    config
  );
  return data;
}

export async function uploadPerson(body: { token: string; person: File }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const formData = new FormData();
  formData.append("file", body.person);

  const response = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/person/ngarko`,
    formData,
    config
  );

  return response.data;
}

export async function getSpecialistet(body: { token: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/person/specialistet`,
    config
  );
  return data;
}

export async function getSpecialistetListing(body: { token: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/person/specialisted-listing`,
    config
  );
  return data;
}

export async function getPersonInfo(body: { token: string; klientId: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/person/${body.klientId}`,
    config
  );
  return data;
}

export async function caktoSpecialistRast(body: {
  token: string;
  personId: number;
  rastId: number;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/portofol/cakto-rast?rastId=${body.rastId}&personId=${body.personId}`,
    {},
    config
  );
  return data;
}

export async function subjekteRastiPerson(body: {
  token: string;
  rastId: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/person/subjekte-rasti/${body.rastId}`,
    config
  );
  return data;
}

// Rast API Functions
export async function getRastiDetails(body: { token: string; rastId: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/rast/${body.rastId}`,
    config
  );
  return data;
}

export async function getRaste(body: { token: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(`${COMMON_ENUMS.BASE_API}/api/rast`, config);
  return data;
}

export async function getRasteListing(body: { token: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/rast/rast-listings`,
    config
  );
  return data;
}

export async function getRastiDebitor(body: { token: string; rastId: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/rast/debitor/${body.rastId}`,
    config
  );
  return data;
}

export async function getRastHipotekues(body: {
  token: string;
  rastId: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/rast/hipotekues-list/${body.rastId}`,
    config
  );
  return data;
}

export async function getRastDorezanes(body: {
  token: string;
  rastId: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/rast/dorezanes-list/${body.rastId}`,
    config
  );
  return data;
}

export async function makeSpecialList(body: { token: string; rastId: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/rast/kalo-special/${body.rastId}`,
    config
  );
  return data;
}

export async function getHipotekues(body: { token: string; rastId: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/rast/hipotekues/${body.rastId}`,
    config
  );
  return data;
}

export async function getDorezanes(body: { token: string; rastId: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/rast/dorezanes/${body.rastId}`,
    config
  );
  return data;
}

export async function getSpecialList(body: { token: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/portofol/special-list`,
    config
  );
  return data;
}

export async function updateRast(body: {
  token: string;
  rastId: number;
  dega: string;
  ekSipasDeges: string;
  ekSipasMaturimit: string;
  fazaPermbarimit: string;
  klasifikimKredie: string;
  kodiKlientit: string;
  portofoli: string;
  segmenti: string;
  situateLigjore: string;
  zyraPermbarimore: string;
  statusVerbal: string;
  sipasUE: number;
  detyrimitTotal: number;
  boa: number;
  vleraLekeMomentiBlerjes: number;
  vleraBlerjesDetyrimit: number;
  konvertimiNeEuro: number;
  dataMarreveshjes: string;
  shumeMarveshjes: number;
  perqindjaMarveshjes: number;
  afatiNeMuaj: number;
  specialList: boolean;
  vleraProvigjonit: string;
  currency: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    dega: body.dega,
    ekSipasDeges: body.ekSipasDeges,
    ekSipasMaturimit: body.ekSipasMaturimit,
    fazaPermbarimit: body.fazaPermbarimit,
    klasifikimKredie: body.klasifikimKredie,
    kodiKlientit: body.kodiKlientit,
    portofoli: body.portofoli,
    segmenti: body.segmenti,
    situateLigjore: body.situateLigjore,
    zyraPermbarimore: body.zyraPermbarimore,
    statusVerbal: body.statusVerbal,
    sipasUE: body.sipasUE,
    detyrimitTotal: body.detyrimitTotal,
    boa: body.boa,
    vleraLekeMomentiBlerjes: body.vleraLekeMomentiBlerjes,
    vleraBlerjesDetyrimit: body.vleraBlerjesDetyrimit,
    konvertimiNeEuro: body.konvertimiNeEuro,
    dataMarreveshjes: body.dataMarreveshjes,
    shumeMarveshjes: body.shumeMarveshjes,
    perqindjaMarveshjes: body.perqindjaMarveshjes,
    afatiNeMuaj: body.afatiNeMuaj,
    specialList: body.specialList,
    vleraProvigjonit: body.vleraProvigjonit,
    currency: body.currency,
  };

  const { data } = await axios.put(
    `${COMMON_ENUMS.BASE_API}/api/rast/update/${body.rastId}`,
    requestBody,
    config
  );
  return data;
}

export async function uploadRast(body: {
  token: string;
  rasti: File;
  portofolId: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const formData = new FormData();
  formData.append("file", body.rasti);
  formData.append("portofolId", body.portofolId);

  const response = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/rast/ngarko`,
    formData,
    config
  );

  return response.data;
}

export async function mbyllRastin(body: { token: string; rastId: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/rast/mbyll-rast?rastId=${body.rastId}`,
    {},
    config
  );
  return data;
}

export async function rasteTeMbyllura(body: { token: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/rast/mbyllura`,
    config
  );
  return data;
}

export async function fshiRastin(body: { token: string; rastId: number }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.delete(
    `${COMMON_ENUMS.BASE_API}/api/rast/${body.rastId}`,
    config
  );
  return data;
}

// Koment API Functions
export async function getKomentet(body: { token: string; rastId: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
    params: {
      rastId: body.rastId,
    },
  };

  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/koment/komentet/${body.rastId}`,
    config
  );
  return data;
}

export async function addKoment(body: {
  token: string;
  rastId: string;
  koment: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    rastId: body.rastId,
    comment: body.koment,
  };

  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/koment`,
    requestBody,
    config
  );
  return data;
}

// Plan Pagese API Functions
export async function getPlanPageseKeste(body: {
  token: string;
  rastId: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/pagesa/keste/${body.rastId}`,
    config
  );
  return data;
}

export async function getPlanPagesaBullet(body: {
  token: string;
  rastId: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/pagesa/bullet/${body.rastId}`,
    config
  );
  return data;
}

export async function getPlanPagesaPeriodike(body: {
  token: string;
  rastId: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/pagesa/periodike/${body.rastId}`,
    config
  );
  return data;
}

export async function getPlanPagesaPjesshme(body: {
  token: string;
  rastId: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/pagesa/pjesshme/${body.rastId}`,
    config
  );
  return data;
}

export async function krijoPlanPageseKeste(body: {
  token: string;
  rastId: number;
  interesiVjetor: number;
  kamatVonese: number;
  monedha: string;
  kursiEuro: number;
  dataNisjes: string;
  numriPagesave: number;
  detyrimi: number;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    rastId: body.rastId,
    interesiVjetor: body.interesiVjetor,
    kamatVonese: body.kamatVonese,
    dataNisjes: body.dataNisjes,
    monedha: body.monedha,
    kursiEuro: body.kursiEuro,
    numriPagesave: body.numriPagesave,
    detyrimi: body.detyrimi,
  };
  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/pagesa/keste`,
    requestBody,
    config
  );
  return data;
}

export async function paguajKestin(body: {
  token: string;
  kestId: number;
  kursi: number;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    kursi: body.kursi,
    kestId: body.kestId,
  };

  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/pagesa/paguaj-kest`,
    requestBody,
    config
  );
  return data;
}

export async function krijoPlanPageseBullet(body: {
  token: string;
  rastId: number;
  interesiVjetor: number;
  kamatVonese: number;
  monedha: string;
  kursiMonedhes: number;
  dataPageses: string;
  detyrimi: number;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    rastId: body.rastId,
    interesiVjetor: body.interesiVjetor,
    kamatVonese: body.kamatVonese,
    dataPageses: body.dataPageses,
    monedha: body.monedha,
    kursiMonedhes: body.kursiMonedhes,
    detyrimi: body.detyrimi,
  };
  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/pagesa/bullet`,
    requestBody,
    config
  );
  return data;
}

export async function krijoPlanPagesePeriodike(body: {
  token: string;
  rastId: number;
  interesiVjetor: number;
  kamatVonese: number;
  monedha: string;
  kursiMonedhes: number;
  intervali: number;
  detyrimi: number;
  dataNisjes: string;
  numriPagesave: number;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    rastId: body.rastId,
    interesiVjetor: body.interesiVjetor,
    kamatVonese: body.kamatVonese,
    monedha: body.monedha,
    kursiMonedhes: body.kursiMonedhes,
    intervali: body.intervali,
    detyrimi: body.detyrimi,
    dataNisjes: body.dataNisjes,
    numriPagesave: body.numriPagesave,
  };
  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/pagesa/periodike`,
    requestBody,
    config
  );
  return data;
}

export async function krijoPlanPagesePjesshme(body: {
  token: string;
  rastId: number;
  interesiVjetor: number;
  kamatVonese: number;
  monedha: string;
  kursiMonedhes: number;
  dataPageses: string;
  detyrimi: number;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    rastId: body.rastId,
    interesiVjetor: body.interesiVjetor,
    kamatVonese: body.kamatVonese,
    monedha: body.monedha,
    kursiMonedhes: body.kursiMonedhes,
    dataPageses: body.dataPageses,
    detyrimi: body.detyrimi,
  };
  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/pagesa/pjesshme`,
    requestBody,
    config
  );
  return data;
}

// Logs
export async function getLogs(body: { token: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const { data } = await axios.get(`${COMMON_ENUMS.BASE_API}/api/logs`, config);
  return data;
}

//Kerkesat
export async function getKerkesatStatus(body: { token: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/aprovim/status-aprovimesh`,
    config
  );
  return data;
}

export async function getKerkesatPerAprovim(body: { token: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/aprovim/pending`,
    config
  );
  return data;
}

export async function aprovoKerkesen(body: {
  token: string;
  id: number;
  koment: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    id: body.id,
    koment: body.koment,
  };

  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/aprovim/aprovo`,
    requestBody,
    config
  );
  return data;
}

export async function refuzoKerkesen(body: {
  token: string;
  id: number;
  koment: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    id: body.id,
    koment: body.koment,
  };

  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/aprovim/refuzo`,
    requestBody,
    config
  );
  return data;
}

// Upload Dokuments Function
export async function uploadDokument(body: {
  token: string;
  rastId: string;
  file: File;
  tipiFile: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const formData = new FormData();
  formData.append("file", body.file);
  formData.append("tipiFile", body.tipiFile);

  const response = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/files/rast/${body.rastId}`,
    formData,
    config
  );

  return response.data;
}

export async function getDokumente(body: { token: string; rastId: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/files/get/${body.rastId}`,
    config
  );
  return data;
}

export async function downloadFile(body: {
  token: string;
  fileId: number;
}): Promise<AxiosResponse<Blob>> {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
    responseType: "blob" as const,
  };

  const response = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/files/download/${body.fileId}`,
    config
  );
  return response;
}

export async function fshiDokumet(body: { token: string; fileId: number }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.delete(
    `${COMMON_ENUMS.BASE_API}/api/files/${body.fileId}`,
    config
  );
  return data;
}

//Filtrat e kreditit
export async function getFiltrat(body: { token: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/filter/evidenca-kreditit`,
    config
  );
  return data;
}

//Arketimet
export async function getRaporteArketime(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/arketime`,
    requestBody,
    config
  );
  return data;
}

export async function getRaporteKlasifikimKredie(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/klasifikim-kredie`,
    requestBody,
    config
  );
  return data;
}

export async function getRaporteEvidencaKreditit(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/evidenca-kreditit`,
    requestBody,
    config
  );
  return data;
}

export async function getRaporteMaturim(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/maturim`,
    requestBody,
    config
  );
  return data;
}

export async function getRaportePastDue(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/past-due`,
    requestBody,
    config
  );
  return data;
}

export async function getRaporteArketueshme(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/te-arketueshme`,
    requestBody,
    config
  );
  return data;
}
export async function getRaporteSituateLigjore(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/situate-ligjore`,
    requestBody,
    config
  );
  return data;
}
export async function getRaporteSipasSegmentit(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/sipas-segmentit`,
    requestBody,
    config
  );
  return data;
}
export async function getRaporteSecured(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/secured-unsecured`,
    requestBody,
    config
  );
  return data;
}
export async function getRaportePermbarues(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const { data } = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/raport-permbarues`,
    requestBody,
    config
  );
  return data;
}

export async function downloadRaportArketime(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}): Promise<AxiosResponse<Blob>> {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
    responseType: "blob" as const,
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const response = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/arketime/excel`,
    requestBody,
    config
  );
  return response;
}

export async function downloadRaportArketueshme(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}): Promise<AxiosResponse<Blob>> {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
    responseType: "blob" as const,
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const response = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/te-arketueshme/excel`,
    requestBody,
    config
  );
  return response;
}

export async function downloadRaportSituateLigjore(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}): Promise<AxiosResponse<Blob>> {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
    responseType: "blob" as const,
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const response = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/situate-ligjore/excel`,
    requestBody,
    config
  );
  return response;
}

export async function downloadRaportSipasSegmentit(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}): Promise<AxiosResponse<Blob>> {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
    responseType: "blob" as const,
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const response = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/sipas-segmentit/excel`,
    requestBody,
    config
  );
  return response;
}

export async function downloadRaportSecured(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}): Promise<AxiosResponse<Blob>> {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
    responseType: "blob" as const,
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const response = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/secured-unsecured/excel`,
    requestBody,
    config
  );
  return response;
}

export async function downloadRaportPermbarues(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}): Promise<AxiosResponse<Blob>> {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
    responseType: "blob" as const,
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const response = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/raport-permbarues/excel`,
    requestBody,
    config
  );
  return response;
}

export async function downloadKlasifikimKredie(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}): Promise<AxiosResponse<Blob>> {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
    responseType: "blob" as const,
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const response = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/klasifikim-kredie/excel`,
    requestBody,
    config
  );
  return response;
}

export async function downloadRaportEvidencaKreditit(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}): Promise<AxiosResponse<Blob>> {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
    responseType: "blob" as const,
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const response = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/evidenca-kreditit/excel`,
    requestBody,
    config
  );
  return response;
}

export async function downloadRaportMaturim(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}): Promise<AxiosResponse<Blob>> {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
    responseType: "blob" as const,
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const response = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/maturim/excel`,
    requestBody,
    config
  );
  return response;
}

export async function downloadRaportPastDue(body: {
  token: string;
  specialistId: number;
  from: string;
  to: string;
}): Promise<AxiosResponse<Blob>> {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
    responseType: "blob" as const,
  };

  const requestBody = {
    specialistId: body.specialistId,
    from: body.from,
    to: body.to,
  };

  const response = await axios.post(
    `${COMMON_ENUMS.BASE_API}/api/raporte/past-due/excel`,
    requestBody,
    config
  );
  return response;
}

//Permbarues

export async function getPermbaruesList(body: { token: string }) {
  const config = {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
  };
  const { data } = await axios.get(
    `${COMMON_ENUMS.BASE_API}/api/permbarues/list`,
    config
  );
  return data;
}
