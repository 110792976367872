import { Column } from "@tanstack/react-table";
import { cn } from "../../../lib/utils.ts";
import { Button } from "../../ui/button";

interface DataTableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  title,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) {
  if (!column.getCanSort()) {
    return <div className={cn("flex items-center", className)}>{title}</div>;
  }

  return (
    <div className={cn("flex items-center bg-white rounded-md")}>
      <Button
        variant="ghost"
        size="sm"
        className="data-[state=open]:bg-accent text-nowrap text-start"
      >
        <span className="text-xs">{title}</span>
      </Button>
    </div>
  );
}
