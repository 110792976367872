import { ArrowRight } from "lucide-react";
import { useEffect, useState } from "react";
import logoImg from "../../assets/images/logo.svg";
import { cn } from "../../lib/utils.ts";
import { NavItems } from "../constants/side-nav.tsx";
import { Button } from "../ui/button.tsx";
import { SideNav } from "./SideNav.tsx";
import { useKeycloak } from "@react-keycloak/web";

interface SidebarProps {
  className?: string;
}

export default function Sidebar({ className }: SidebarProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const {keycloak} = useKeycloak();

  useEffect(() => {
    const updateNumColumns = () => {
      if (window.innerWidth < 768) {
        setIsOpen(false);
      }
    };

    updateNumColumns();

    const handleResize = () => {
      updateNumColumns();
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggle = () => {
    setStatus(true);
    setIsOpen((prev) => !prev);
    setTimeout(() => setStatus(false), 500);
  };

  return keycloak.authenticated ? (
    <nav
      className={cn(
        `relative h-screen border-r pt-5 md:block z-50`,
        !isOpen
          ? "w-[100px] animate-out duration-500"
          : "w-[250px] animate-in duration-500",
        className
      )}
    >
      <Button
        size="icon"
        className={cn(
          "absolute -right-4 top-5 cursor-pointer rounded-full border bg-[#A4ADFD] text-foreground",
          isOpen && "rotate-180"
        )}
        onClick={handleToggle}
      >
        <ArrowRight color="white" />
      </Button>

      <div
        className={cn(
          "overflow-y-auto flex flex-col h-full",
          !isOpen ? "animate-out duration-500" : "animate-in duration-500"
        )}
      >
        <div className="grid grid-rows-5 grid-flow-col h-full">
          <div
            className={`row-span-1 flex flex-col justify-center items-center ${!isOpen && "p-1"}`}
          >
            <img alt="logo" width={140} src={logoImg} />
          </div>
          <div className={`row-span-4 overflow-y-auto`}>
            <SideNav items={NavItems} isOpen={isOpen} setIsOpen={setIsOpen} />
          </div>
        </div>
      </div>
    </nav>
  ) : null;
}
