import { Row } from "@tanstack/react-table";

import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { Trash2 } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  handleDeleteRow: (id: number) => void;
}

export function DataTableRowActionsBiznes<TData>({
  row,
  handleDeleteRow,
}: DataTableRowActionsProps<TData>) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const onOpenChange = () => {
    setOpen(!open);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild className="flex items-center my-3">
        <Button
          variant="outline"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted border border-secondary rounded-xl"
        >
          <DotsHorizontalIcon className="h-4 w-4" color="#A4ADFD" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="w-[160px] flex flex-col gap-2"
      >
        <DropdownMenuItem
          onClick={() =>
            navigate(`/portofol/rasti/biznes/${(row.original as any).id}`)
          }
        >
          Me Shume
        </DropdownMenuItem>

        <Dialog open={open} onOpenChange={onOpenChange}>
          <DialogTrigger asChild>
            <Button
              variant={"destructive"}
              className="w-full text-start flex justify-start gap-2 items-center"
            >
              <span>Fshi biznesin</span>
              <Trash2 color="white" size={18} />
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px] border border-secondary">
            <DialogHeader>
              <DialogTitle>Konfirmo fshirjen e biznesit</DialogTitle>
            </DialogHeader>
            <DialogFooter>
              <Button
                variant="default"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                Mbyll
              </Button>
              <Button
                variant="destructive"
                onClick={() => handleDeleteRow((row.original as any).id)}
              >
                Fshij
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
