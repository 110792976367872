import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { useKeycloak } from "@react-keycloak/web";
import { useMutation } from "@tanstack/react-query";
import { Check, ChevronRight, Pencil } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { cn, isAdmin } from "src/lib/utils";
import {
  caktoSpecialistRast,
  getRastiDebitor,
  getRastiDetails,
  getSpecialistet,
  makeSpecialList,
  mbyllRastin,
} from "../../api";
import { Raste, Specialist } from "../types/tabela-portofol";
import { Button } from "../ui/button";
import { Card, CardFooter, CardHeader, CardTitle } from "../ui/card";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Spinner } from "../ui/spinner";
import { useToast } from "../ui/use-toast";
import ModifikRastForm from "./ModifikRastForm";
import { columns } from "./rasti-table/Columns";
import { DataTable } from "./rasti-table/DataTable";
import RastiCards from "./RastiCards";
import RastiDokumente from "./RastiDokumente";
import RastiDokumentUpload from "./RastiDokumentUpload";
import RastiPaymentCards from "./RastiPaymentCards";

export interface Debitor {
  emri: string;
  id: number;
  segmenti: string;
}

export interface EvidencaKreditit {
  [key: string]: string;
}

const Rasti = () => {
  let { rastId } = useParams();
  const { keycloak } = useKeycloak();
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");
  const [rastDetailsData, setRastDetailsData] = useState<Raste | undefined>(
    undefined
  );
  const [rastiDebitor, setRastiDebitor] = useState<Debitor>();
  const { toast } = useToast();
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [specialistet, setSpecialistet] = useState<Specialist[]>([]);
  const [refreshKey, setRefreshKey] = useState(0);

  const specialistetMutation = useMutation({
    mutationFn: getSpecialistet,
    onSuccess: (data) => {
      setSpecialistet(data.content);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const caktoSpecialistMutation = useMutation({
    mutationFn: caktoSpecialistRast,
    onSuccess: (data) => {
      toast({
        variant: "success",
        title: "Success",
        description: "Specialisti u caktua me sukses",
      });
      onOpenChange();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (rastId) {
      const body = { token, rastId };
      rastDetailsMutation.mutate(body);
      rastiDebitorMutation.mutate(body);
    }
  }, [rastId, token]);

  const rastDetailsMutation = useMutation({
    mutationFn: getRastiDetails,
    onSuccess: (data: any) => {
      setRastDetailsData(data);
    },
  });

  const specialistMutation = useMutation({
    mutationFn: makeSpecialList,
    onSuccess: (data: any) => {
      toast({
        variant: "success",
        title: "Success",
        description: "Rasti u shtua ne Special List",
      });
    },
    onError: (error: any) => {
      console.log(error);
    },
  });

  const mbyllRastMutation = useMutation({
    mutationFn: mbyllRastin,
    onSuccess: (data: any) => {
      toast({
        variant: "success",
        title: "Success",
        description: "Rasti u mbyll me sukses",
      });
    },
    onError: (error: any) => {
      console.log(error);
    },
  });

  const rastiDebitorMutation = useMutation({
    mutationFn: getRastiDebitor,
    onSuccess: (data: any) => {
      setRastiDebitor(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleSpecialist = () => {
    if (rastId) {
      const body = { token, rastId };
      specialistMutation.mutate(body);
    }
  };

  const handleMbyllRast = () => {
    if (rastId) {
      const body = { token, rastId };
      mbyllRastMutation.mutate(body);
    }
  };

  useEffect(() => {
    specialistetMutation.mutate({
      token,
    });
  }, [open]);

  const onOpenChange = () => {
    setOpen(!open);
    setValue("");
  };

  const handleSelectionChange = () => {
    caktoSpecialistMutation.mutate({
      token,
      personId: 1,
      rastId: 1,
    });
  };

  const handleDocumentsUpdate = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleReloadRastData = () => {
    if (rastId) {
      const body = { token, rastId };
      rastDetailsMutation.mutate(body);
    }
    setOpenFormDialog(false);
  };

  if (rastDetailsMutation?.isPending) {
    return (
      <div className="px-5 w-1/2 justify-center mt-5">
        <Card>
          <CardHeader className="flex items-center justify-center">
            <CardTitle>Loading...</CardTitle>
          </CardHeader>
          <CardFooter className="flex items-center justify-center">
            <Spinner fill="black" />
          </CardFooter>
        </Card>
      </div>
    );
  } else {
    return (
      <div className="h-screen relative px-1 w-full">
        <div className="relative w-full z-10">
          <RastiCards
            rastDetailsData={rastDetailsData}
            rastiDebitor={rastiDebitor}
          />
        </div>
        <div className="bg-slate-100 mt-3 rounded-md">
          <DataTable data={[rastDetailsData]} columns={columns} />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 justify-center mt-3 w-full gap-2">
          <div className="col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1">
            <RastiDokumente rastId={rastId} refreshKey={refreshKey} />
          </div>
          <div className="col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1">
            <RastiDokumentUpload
              rastId={rastId}
              onDocumentsUpdate={handleDocumentsUpdate}
            />
          </div>
          <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 w-full gap-2">
              <RastiPaymentCards rastId={rastId} />
            </div>
          </div>
        </div>

        <div className="flex justify-end items-end p-4">
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="outline" className=" bg-secondary text-white">
                <DotsHorizontalIcon color="white" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-80 border-secondary mr-4">
              <div className="grid gap-4">
                {isAdmin(keycloak) && (
                  <Button
                    variant="outline"
                    className={`flex flex-row  items-center bg-secondary text-white ${
                      mbyllRastMutation?.isPending
                        ? "justify-center"
                        : "justify-between"
                    }`}
                    onClick={handleMbyllRast}
                  >
                    {mbyllRastMutation?.isPending ? (
                      <Spinner fill="black" />
                    ) : (
                      "Kalo ne raste te mbyllura"
                    )}
                  </Button>
                )}
                {isAdmin(keycloak) && (
                  <Button
                    variant="outline"
                    className={`flex flex-row  items-center bg-secondary text-white  ${
                      specialistMutation?.isPending
                        ? "justify-center"
                        : "justify-between"
                    }`}
                    onClick={handleSpecialist}
                  >
                    {specialistMutation?.isPending ? (
                      <Spinner fill="black" />
                    ) : (
                      "Kalo ne special list"
                    )}
                  </Button>
                )}
                {isAdmin(keycloak) && (
                  <Dialog open={open} onOpenChange={onOpenChange}>
                    <DialogTrigger className="h-9 px-4 py-2 w-full flex-row  bg-secondary text-primary-foreground shadow hover:bg-primary/90 inline-flex items-center justify-between rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
                      Cakto nje specialist
                      <ChevronRight />
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-[425px] border border-secondary">
                      <Command>
                        <CommandInput placeholder="Kerko nje specialist..." />
                        <CommandList>
                          {specialistet.length > 0 &&
                            specialistet.map((specialist) => (
                              <CommandItem
                                key={specialist.id}
                                value={specialist.emri}
                                onSelect={(currentValue) =>
                                  setValue(
                                    currentValue === value ? "" : currentValue
                                  )
                                }
                              >
                                <Check
                                  className={cn(
                                    "mr-2 h-4 w-4",
                                    value === specialist.emri
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                                {`${specialist.emri} ${specialist.mbiemer}`}
                              </CommandItem>
                            ))}

                          <CommandEmpty>No results found.</CommandEmpty>
                        </CommandList>
                        <Button
                          onClick={handleSelectionChange}
                          className="mt-4 justify-center items-center  bg-secondary text-white"
                          variant="outline"
                        >
                          {caktoSpecialistMutation?.isPending ? (
                            <Spinner fill="black" />
                          ) : (
                            "Cakto"
                          )}
                        </Button>
                      </Command>
                    </DialogContent>
                  </Dialog>
                )}
                {!isAdmin(keycloak) ||
                  (isAdmin(keycloak) && (
                    <Dialog
                      open={openFormDialog}
                      onOpenChange={setOpenFormDialog}
                    >
                      <DialogTrigger asChild>
                        <Button
                          className="w-full bg-secondary text-white flex felx-row justify-between gap-2"
                          variant={"outline"}
                        >
                          Modifiko rastin
                          <Pencil height={15} width={15} />
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="sm:max-w-7xl w-full h-full overflow-auto">
                        <ModifikRastForm
                          rastDetailsData={rastDetailsData}
                          handleReloadRastData={handleReloadRastData}
                        />
                      </DialogContent>
                    </Dialog>
                  ))}
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    );
  }
};

export default Rasti;
