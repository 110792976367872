import { useNavigate } from "react-router-dom";
import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <main className="content flex self-center justify-center max-w-5xl h-full">
      <Card>
        <CardHeader className="items-center">
          <CardTitle>Opps! Dicka shkoj gabim</CardTitle>
        </CardHeader>
        <CardContent className="space-y-2 flex flex-row justify-center">
          Mesa duket faqja qe kerkoni nuk egziston
        </CardContent>
        <CardFooter className="space-y-2 flex flex-row justify-center">
          <Button type="submit" onClick={() => navigate("/")}>
            Kthehu ne faqen kryesore
          </Button>
        </CardFooter>
      </Card>
    </main>
  );
};

export default NotFoundPage;
