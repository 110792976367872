import { Row } from "@tanstack/react-table";

import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { useMutation } from "@tanstack/react-query";
import { Check } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Raste, Specialist } from "src/components/types/tabela-portofol";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "src/components/ui/command";
import { Dialog, DialogContent, DialogTrigger } from "src/components/ui/dialog";
import { Spinner } from "src/components/ui/spinner";
import { useToast } from "src/components/ui/use-toast";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { cn } from "src/lib/utils";
import { caktoSpecialistRast, getSpecialistet } from "../../../api";
import { Button } from "../../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
}

export function DataTableRowActions<TData>({
  row,
}: DataTableRowActionsProps<TData>) {
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");
  const [specialistet, setSpecialistet] = useState<Specialist[]>([]);
  const [specailist, setSpecialist] = useState<Specialist>();

  const specialistetMutation = useMutation({
    mutationFn: getSpecialistet,
    onSuccess: (data) => {
      setSpecialistet(data.content);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const caktoSpecialistMutation = useMutation({
    mutationFn: caktoSpecialistRast,
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Success",
        description: "Specialisti u caktua me sukses",
      });
      setOpen(false);
      setValue("");
      setSpecialist(undefined);
      setDropDownOpen(false);
    },
    onError: (error) => {
      console.log(error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: error.message,
      });
    },
  });

  useEffect(() => {
    specialistetMutation.mutate({
      token,
    });
  }, [open]);

  const onOpenChange = () => {
    setOpen(!open);
    setValue("");
  };

  const handleSelectionChange = () => {
    caktoSpecialistMutation.mutate({
      token,
      personId: specailist?.id || 0,
      rastId: (row.original as Raste).id,
    });
  };

  return (
    <DropdownMenu open={dropDownOpen} onOpenChange={setDropDownOpen}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted border border-secondary rounded-xl"
        >
          <DotsHorizontalIcon className="h-4 w-4" color="#A4ADFD" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuItem
          onClick={() =>
            navigate(`/portofol/rasti/${(row.original as Raste).id}`)
          }
        >
          Me Shume
        </DropdownMenuItem>
        <Dialog open={open} onOpenChange={onOpenChange}>
          <DialogTrigger className="text-sm px-2">{`Cakto nje specialist >`}</DialogTrigger>
          <DialogContent className="sm:max-w-[425px] border border-secondary">
            <Command>
              <CommandInput placeholder="Kerko nje specialist..." />
              <CommandList>
                {specialistet.length > 0 &&
                  specialistet.map((specialist) => (
                    <CommandItem
                      key={specialist.id}
                      value={specialist.emri}
                      onSelect={(currentValue) => {
                        setValue(currentValue === value ? "" : currentValue);
                        setSpecialist(
                          specialistet.find((s) => s.id === specialist.id)
                        );
                      }}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          value === specialist.emri
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {`${specialist.emri} ${specialist.mbiemer}`}
                    </CommandItem>
                  ))}

                <CommandEmpty>No results found.</CommandEmpty>
              </CommandList>
              <Button
                onClick={handleSelectionChange}
                className="mt-4 justify-center items-center"
                variant="default"
              >
                {caktoSpecialistMutation?.isPending ? <Spinner fill="black"/> : "Cakto"}
              </Button>
            </Command>
          </DialogContent>
        </Dialog>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
