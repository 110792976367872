import { Row } from "@tanstack/react-table";

import { Raste } from "src/components/types/tabela-portofol";
import { Spinner } from "src/components/ui/spinner";
import { Button } from "../../ui/button";
interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  handleDeleteRow: (id: number) => void;
  deleteRastMutation: any;
  buttonClicked: number;
  onOpenChange: () => void;
}

export function DataTableRowActions<TData>({
  row,
  handleDeleteRow,
  deleteRastMutation,
  buttonClicked,
  onOpenChange,
}: DataTableRowActionsProps<TData>) {
  return (
    <Button
      variant="destructive"
      onClick={() => handleDeleteRow((row.original as Raste).id)}
      className="py-2 my-2 mx-2"
    >
      {deleteRastMutation.isPending &&
      buttonClicked === (row.original as Raste).id ? (
        <Spinner fill="black" />
      ) : (
        "Refuzo"
      )}
    </Button>
  );
}
