import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TabelaPortofol } from "../../components/types/tabela-portofol";

interface PortofolTableState {
  table_data: TabelaPortofol[];
  loading: boolean;
}

const initialState: PortofolTableState = {
  table_data: [],
  loading: false,
};

export const portofolTableSlice = createSlice({
  name: "portofol_table",
  initialState,
  reducers: {
    addTableData: (state, action: PayloadAction<TabelaPortofol>) => {
      state.table_data = [action.payload];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { addTableData, setLoading } = portofolTableSlice.actions;

export default portofolTableSlice.reducer;
