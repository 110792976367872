import { ColumnDef } from "@tanstack/react-table";
import { Raste } from "src/components/types/tabela-portofol.ts";
import { DataTableColumnHeader } from "./DataTableColumnHeader.tsx";

export const columns: ColumnDef<Raste | any | undefined>[] = [
  {
    accessorKey: "dega",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Rrethi/Dega" />
    ),
    cell: ({ row }) => {
      const dega = row.original && row.original.dega ? row.original.dega : "";
      return dega;
    },
  },
  {
    accessorKey: "currency",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Currency" />
    ),
    cell: ({ row }) => {
      const currency =
        row.original && row.original.currency ? row.original.currency : "";
      return currency;
    },
  },
  {
    accessorKey: "sipasUE",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Vlera e Detyrimit Sipas UE"
      />
    ),
    cell: ({ row }) => {
      const sipasUE =
        row.original && row.original.sipasUE ? row.original.sipasUE : "";
      return sipasUE;
    },
  },
  {
    accessorKey: "vleraNePermbarim",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Vlera e Detyrimit të përmbarimit"
      />
    ),
    cell: ({ row }) => {
      const vleraPermbarimit =
        row.original && row.original.detyrimitTotal
          ? row.original.detyrimitTotal
          : "";
      return vleraPermbarimit;
    },
  },
  {
    accessorKey: "vleraPermbarimitLeke",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Vlera ne Leke e Detyrimit"
      />
    ),
    cell: ({ row }) => {
      const vleraPermbarimitLeke =
        row.original && row.original.vleraLekeMomentiBlerjes
          ? row.original.vleraLekeMomentiBlerjes
          : "";
      return vleraPermbarimitLeke;
    },
  },
  {
    accessorKey: "vleraBlerjesDetyrimit",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Vlera e blerjes e Detyrimit"
      />
    ),
    cell: ({ row }) => {
      const vleraBlerjesDetyrimit =
        row.original && row.original.vleraBlerjesDetyrimit
          ? row.original.vleraBlerjesDetyrimit
          : "";
      return vleraBlerjesDetyrimit;
    },
  },
  {
    accessorKey: "konvertimiNeEuro",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Konvertimi i Detyrimit ne Euro"
      />
    ),
    cell: ({ row }) => {
      const konvertimiNeEuro =
        row.original && row.original.konvertimiNeEuro
          ? row.original.konvertimiNeEuro
          : "";
      return konvertimiNeEuro;
    },
  },
  {
    accessorKey: "dataMarreveshjes",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Data e marreveshjes" />
    ),
    cell: ({ row }) => {
      const dataMarreveshjes =
        row.original && row.original.dataMarreveshjes
          ? row.original.dataMarreveshjes
          : "";
      return dataMarreveshjes;
    },
  },
  {
    accessorKey: "shumeMarveshjes",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Shuma e marreveshjes" />
    ),
    cell: ({ row }) => {
      const shumeMarveshjes =
        row.original && row.original.shumeMarveshjes
          ? row.original.shumeMarveshjes
          : "";
      return shumeMarveshjes;
    },
  },
  {
    accessorKey: "perqindjaMarveshjes",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Përqindje e marrëveshjes" />
    ),
    cell: ({ row }) => {
      const perqindjaMarveshjes =
        row.original && row.original.perqindjaMarveshjes
          ? row.original.perqindjaMarveshjes
          : "";
      return perqindjaMarveshjes;
    },
  },
  {
    accessorKey: "afatiNeMuaj",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Afati i marrëveshjes" />
    ),
    cell: ({ row }) => {
      const afatiNeMuaj =
        row.original && row.original.afatiNeMuaj
          ? row.original.afatiNeMuaj
          : "";
      return afatiNeMuaj;
    },
  },
];

export default columns;
