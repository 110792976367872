import { Portofoli } from "../types/tabela-portofol";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";

interface PortofolCardsProps {
  portofolDetails: Portofoli;
}

const PortofolCards = ({ portofolDetails }: PortofolCardsProps) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 w-full gap-4">
      <div className="col-span-1 sm:col-span-2 lg:col-span-1">
        <Card>
          <CardHeader>
            <CardTitle>Pasqyra</CardTitle>
          </CardHeader>
          <div className="flex flex-row">
            <CardContent className="p-3 m-3">
              <p>FX EUR</p>
              <p className="font-semibold">{portofolDetails?.fxEUR?.kursi}</p>
            </CardContent>
            <CardContent className=" p-3 m-3">
              <p>FX USD</p>
              <p className="font-semibold">{portofolDetails?.fxUSD?.kursi}</p>
            </CardContent>
          </div>
        </Card>
      </div>
      <div className="col-span-1 sm:col-span-2 lg:col-span-3 bg-slate-50 flex flex-row justify-evenly gap-3 w-full rounded-lg">
        <Card className="w-full">
          <CardHeader>
            <CardTitle>Pasqyra</CardTitle>
          </CardHeader>
          <div className="flex flex-row">
            <CardContent className=" p-3 m-3 w-full sm:w-1/3">
              <p>VLERA TOTALE</p>
              <p className="font-semibold">{portofolDetails?.vleraTotale}</p>
            </CardContent>
            <CardContent className=" p-3 m-3 w-full sm:w-1/3">
              <p>VLERA E PORTOFOLIT</p>
              <p className="font-semibold">
                {portofolDetails?.vleraPortofolit}
              </p>
            </CardContent>
            <CardContent className=" p-3 m-3 w-full sm:w-1/3">
              <p>ARKËTIME (DERI ME TANI)</p>
              <p className="font-semibold">{portofolDetails?.arketim}</p>
            </CardContent>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default PortofolCards;
