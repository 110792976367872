import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const isAdmin = (keycloak: any) => {
  return (
    keycloak?.tokenParsed?.resource_access?.["collection-app"]?.roles?.includes(
      "PLATFORM_ADMIN"
    ) ?? false
  );
};

export const accessToken = (keycloak: any) => {
  return keycloak.token ?? null;
};

export const pairValue = (key: string) => {};
