import { ArrowUpIcon } from "@radix-ui/react-icons";
import { Column } from "@tanstack/react-table";

import { SquareChevronDown } from "lucide-react";
import { cn } from "../../../lib/utils.ts";
import { Button } from "../../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";

interface DataTableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
  dropdownContent?: { id: number; label: string }[];
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  title,
  className,
  dropdownContent,
}: DataTableColumnHeaderProps<TData, TValue>) {
  if (!column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>;
  }

  const items = {
    kodiKlientit: [
      { id: 0, label: "Kodi Klientit" },
      { id: 1, label: "Njeriu" },
    ],
    emertimiPortofolit: [
      { id: 0, label: "Portofoli im" },
      { id: 1, label: "Jo i imi" },
    ],
    emerMbiemer: [
      { id: 0, label: "Filani" },
      { id: 1, label: "Fisteku" },
    ],
  };

  return (
    <div className={cn("flex items-center space-x-2", className)}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="-ml-3 h-12 data-[state=open]:bg-accent text-nowrap text-start bg-white"
          >
            <span className="text-xs">{title}</span>

            <SquareChevronDown className="ml-2 h-4 w-4" color="#737EDE" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          {items[column.id as keyof typeof items]?.map((element: any) => (
            <DropdownMenuItem key={element.id}>
              <ArrowUpIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
              {element.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
