import { Column } from "@tanstack/react-table";

import { cn } from "../../../lib/utils.ts";

interface DataTableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  title,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) {
  if (!column.getCanSort()) {
    return (
      <div className={cn("flex items-center space-x-2", className)}>
        {title}
      </div>
    );
  }

  return (
    <div className={cn("flex items-center text-xs text-nowrap")}>{title}</div>
  );
}
