import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function PrivateRoutes() {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async () => {
    const redirectUri = window.location.origin + "/";
    await keycloak.login({ redirectUri });
  };

  useEffect(() => {
    const checkLoginStatus = async () => {
      if (!keycloak.authenticated) {
        await keycloak.login();
      } else if (location.pathname === "/") {
        navigate("/portofole");
      }
    };

    checkLoginStatus();
  }, [keycloak, navigate, location.pathname]);

  if (!keycloak.authenticated) {
    handleLogin();
    return null;
  }

  return keycloak.authenticated && <Outlet />;
}
