import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { fshiRastin, rasteTeMbyllura } from "../../api";
import { Raste } from "../types/tabela-portofol";
import { Card, CardFooter, CardHeader, CardTitle } from "../ui/card";
import { Spinner } from "../ui/spinner";
import { useToast } from "../ui/use-toast";
import { columns } from "./raste-cloesd-table/Columns";
import { DataTable } from "./raste-cloesd-table/DataTable";

const RasteClosed = () => {
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");
  const filterData = getItem("filter");
  const [raste, setRaste] = useState<Raste[]>([]);
  const { toast } = useToast();
  const [deletionSuccess, setDeletionSuccess] = useState(false);

  const transformData = (data: any) => {
    return data.map((item: any) => {
      return {
        ...item,
        segmenti: filterData.segmenti[item.segmenti] || item.segmenti,
        klasifikimKredie:
          filterData.klasifikimKredie[item.klasifikimKredie] ||
          item.klasifikimKredie,
        ekSipasMaturimit:
          filterData.ekSipasMaturimit[item.ekSipasMaturimit] ||
          item.ekSipasMaturimit,
        situateLigjore:
          filterData.situateLigjore[item.situateLigjore] || item.situateLigjore,
        statusVerbal:
          filterData.statusVerbal[item.statusVerbal] || item.statusVerbal,
        fazaPermbarimit:
          filterData.fazaPermbarimit[item.fazaPermbarimit] ||
          item.fazaPermbarimit,
      };
    });
  };

  const rasteClosedMutation = useMutation({
    mutationFn: rasteTeMbyllura,
    onSuccess: (data) => {
      let transformedData = transformData(data.content);
      setRaste(transformedData);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const fshijRastinMutation = useMutation({
    mutationFn: fshiRastin,
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Success!",
        description: "Rasti u fshi me sukses",
      });
      rasteClosedMutation.mutate({ token });
      setDeletionSuccess(true);
    },
    onError: (error) => {
      console.log(error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: error.message,
      });
    },
  });

  useEffect(() => {
    rasteClosedMutation.mutate({ token });
  }, [token]);

  const handleDeleteRow = (id: number) => {
    fshijRastinMutation.mutate({ token, rastId: id });
  };

  if (rasteClosedMutation?.isPending) {
    return (
      <div className="px-5 w-1/2 justify-center mt-5">
        <Card>
          <CardHeader className="flex items-center justify-center">
            <CardTitle>Loading...</CardTitle>
          </CardHeader>
          <CardFooter className="flex items-center justify-center">
            <Spinner fill="black" />
          </CardFooter>
        </Card>
      </div>
    );
  }

  return (
    <div className="relative px-5 gap-4 flex flex-col w-full">
      {/* <div className="bg-white rounded-md justify-center overflow-x-auto"> */}
      <DataTable
        data={raste}
        columns={columns}
        handleDeleteRow={handleDeleteRow}
        deletionSuccess={deletionSuccess}
      />
      {/* </div> */}
    </div>
  );
};

export default RasteClosed;
