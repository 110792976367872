import { zodResolver } from "@hookform/resolvers/zod";
import {
  DropdownMenu,
  DropdownMenuContent,
} from "@radix-ui/react-dropdown-menu";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { z } from "zod";
import { updateRast } from "../../api";
import { Button } from "../ui/button";
import { Card, CardFooter, CardHeader, CardTitle } from "../ui/card";
import { DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Spinner } from "../ui/spinner";
import { useToast } from "../ui/use-toast";

const formSchema = z.object({
  kodiKlientit: z
    .string()
    .min(1, { message: "Kodi i klientit nuk duhet të jetë bosh" }),
  portofoli: z.string().min(1, { message: "Portofoli nuk duhet të jetë bosh" }),
  segmenti: z.string().min(1, { message: "Segmenti nuk duhet të jetë bosh" }),
  klasifikimKredie: z
    .string()
    .min(1, { message: "Klasifikimi i kredise nuk duhet të jetë bosh" }),
  ekSipasMaturimit: z
    .string()
    .min(1, { message: "Krediti sipas maturimit nuk duhet të jetë bosh" }),
  ekSipasDeges: z
    .string()
    .min(1, { message: "Krediti sipas degeve nuk duhet të jetë bosh" }),
  situateLigjore: z
    .string()
    .min(1, { message: "Situata ligjore nuk duhet të jetë bosh" }),
  zyraPermbarimore: z
    .string()
    .min(1, { message: "Zyra permbarimore nuk duhet të jetë bosh" }),
  fazaPermbarimit: z.string().min(1, {
    message: "Faza e procedures permbarimore nuk duhet të jetë bosh",
  }),
  dega: z.string().min(1, { message: "Dega nuk duhet të jetë bosh" }),
  statusVerbal: z
    .string()
    .min(1, { message: "Statusi Verbal nuk duhet të jetë bosh" }),
});

interface ModifikRastFormProps {
  rastDetailsData?: any;
  getFilterMutation?: any;
  handleReloadRastData: () => void;
}

interface EvidencaKreditit {
  [key: string]: string;
}
const ModifikRastForm = ({
  rastDetailsData,
  getFilterMutation,
  handleReloadRastData,
}: ModifikRastFormProps) => {
  const { getItem } = useLocalStorage("token");
  const token = getItem("token");
  const filterData = getItem("filter");
  const { toast } = useToast();

  console.log("rastDetailsData", rastDetailsData);

  const updateRastMutation = useMutation({
    mutationFn: updateRast,
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Sukses!",
        description: "Rasti u përditësua me sukses!",
      });
      handleReloadRastData();
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      kodiKlientit: rastDetailsData?.kodiKlientit || "",
      portofoli: rastDetailsData?.portofoli || "",
      segmenti: rastDetailsData?.segmenti || "",
      klasifikimKredie: rastDetailsData?.klasifikimKredie || "",
      ekSipasMaturimit: rastDetailsData?.ekSipasMaturimit || "",
      ekSipasDeges: rastDetailsData?.evidencaKreditit || "",
      situateLigjore: rastDetailsData?.situateLigjore || "",
      zyraPermbarimore: rastDetailsData?.zyraPermbarimore || "",
      fazaPermbarimit: rastDetailsData?.fazaPermbarimit || "",
      dega: rastDetailsData?.dega || "",
      statusVerbal: rastDetailsData?.statusVerbal || "",
    },
  });

  const onSubmit = (formData: z.infer<typeof formSchema>) => {
    const requestBody = {
      ...formData,
      sipasUE: rastDetailsData?.sipasUE || 0,
      detyrimitTotal: rastDetailsData?.detyrimitTotal || 0,
      boa: rastDetailsData?.boa || 0,
      vleraLekeMomentiBlerjes: rastDetailsData?.vleraLekeMomentiBlerjes || 0,
      vleraBlerjesDetyrimit: rastDetailsData?.vleraBlerjesDetyrimit || 0,
      konvertimiNeEuro: rastDetailsData?.konvertimiNeEuro || 0,
      dataMarreveshjes: rastDetailsData?.dataMarreveshjes || "",
      shumeMarveshjes: rastDetailsData?.shumeMarveshjes || 0,
      perqindjaMarveshjes: rastDetailsData?.perqindjaMarveshjes || 0,
      afatiNeMuaj: rastDetailsData?.afatiNeMuaj || 0,
      specialList: rastDetailsData?.specialList || false,
      vleraProvigjonit: rastDetailsData?.vleraProvigjonit || "",
      currency: rastDetailsData?.currency || "",
      token,
      rastId: rastDetailsData?.id || undefined,
    };
    updateRastMutation.mutate(requestBody);
  };

  if (getFilterMutation?.isPending) {
    return (
      <div className="px-5 w-full flex justify-center items-center mt-5">
        <Card className="w-1/2 max-h-[150px]">
          <CardHeader className="flex items-center justify-center">
            <CardTitle>Loading...</CardTitle>
          </CardHeader>
          <CardFooter className="flex items-center justify-center">
            <Spinner fill="black" />
          </CardFooter>
        </Card>
      </div>
    );
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="kodiKlientit"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Kodi Klientit</FormLabel>
                <FormControl>
                  <Input placeholder="Kodi Klientit" {...field} disabled />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="portofoli"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Portofoli</FormLabel>
                <FormControl>
                  <Input placeholder="Portofoli" {...field} disabled />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="segmenti"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Segmenti</FormLabel>
                <FormControl>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline" className="w-full">
                        {field.value
                          ? filterData?.segmenti[field.value as any]
                          : "Zgjidh segmentin"}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      align="center"
                      className="bg-secondary max-h-[300px] min-w-fit w-96 overflow-auto"
                    >
                      {filterData &&
                        Object.entries(filterData.segmenti).map(
                          ([key, value]: any) => (
                            <DropdownMenuItem
                              key={key}
                              onClick={() => field.onChange(key)}
                            >
                              <Label className="font-semibold space-y-3 my-1 py-1 w-full border border-r-0 border-l-0 border-t-0 border-white">
                                {value}
                              </Label>
                            </DropdownMenuItem>
                          )
                        )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="klasifikimKredie"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Klasifikim Kredie</FormLabel>
                <FormControl>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline" className="w-full">
                        {field.value
                          ? filterData?.klasifikimKredie[field.value as any]
                          : "Zgjidh klasifikimin e kredisë"}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      align="center"
                      className="bg-secondary max-h-[300px] min-w-fit w-96 overflow-auto"
                    >
                      {filterData &&
                        Object.entries(filterData.klasifikimKredie).map(
                          ([key, value]: any) => (
                            <DropdownMenuItem
                              key={key}
                              onClick={() => field.onChange(key)}
                            >
                              <Label className="font-semibold space-y-3 my-1 py-1 w-full border border-r-0 border-l-0 border-t-0 border-white">
                                {value}
                              </Label>
                            </DropdownMenuItem>
                          )
                        )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="ekSipasMaturimit"
            render={({ field }) => (
              <FormItem>
                <FormLabel>EK Sipas Maturimit</FormLabel>
                <FormControl>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline" className="w-full">
                        {field.value
                          ? filterData?.ekSipasMaturimit[field.value as any]
                          : "Zgjidh degën"}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      align="center"
                      className="bg-secondary max-h-[300px] min-w-fit w-96 overflow-auto"
                    >
                      {filterData &&
                        Object.entries(filterData.ekSipasMaturimit).map(
                          ([key, value]: any) => (
                            <DropdownMenuItem
                              key={key}
                              onClick={() => field.onChange(key)}
                            >
                              <Label className="font-semibold space-y-3 my-1 py-1 w-full border border-r-0 border-l-0 border-t-0 border-white">
                                {value}
                              </Label>
                            </DropdownMenuItem>
                          )
                        )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="ekSipasDeges"
            render={({ field }) => (
              <FormItem>
                <FormLabel>EK Sipas Degës</FormLabel>
                <FormControl>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline" className="w-full">
                        {field.value
                          ? filterData?.evidencaKreditit[field.value as any]
                          : "Zgjidh degën"}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      align="center"
                      className="bg-secondary max-h-[300px] min-w-fit w-96 overflow-auto"
                    >
                      {filterData &&
                        Object.entries(filterData.evidencaKreditit).map(
                          ([key, value]: any) => (
                            <DropdownMenuItem
                              key={key}
                              onClick={() => field.onChange(key)}
                            >
                              <Label className="font-semibold space-y-3 my-1 py-1 w-full border border-r-0 border-l-0 border-t-0 border-white">
                                {value}
                              </Label>
                            </DropdownMenuItem>
                          )
                        )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="situateLigjore"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Situatë Ligjore</FormLabel>
                <FormControl>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline" className="w-full">
                        {field.value
                          ? filterData?.situateLigjore[field.value as any]
                          : "Zgjidh situatën ligjore"}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      align="center"
                      className="bg-secondary max-h-[300px] min-w-fit w-96 overflow-auto"
                    >
                      {filterData &&
                        Object.entries(filterData.situateLigjore).map(
                          ([key, value]: any) => (
                            <DropdownMenuItem
                              key={key}
                              onClick={() => field.onChange(key)}
                            >
                              <Label className="font-semibold space-y-3 my-1 py-1 w-full border border-r-0 border-l-0 border-t-0 border-white">
                                {value}
                              </Label>
                            </DropdownMenuItem>
                          )
                        )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="zyraPermbarimore"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Zyra Përmbarimore</FormLabel>
                <FormControl>
                  <Input placeholder="Zyra Përmbarimore" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="fazaPermbarimit"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Faza Përmbarimit</FormLabel>
                <FormControl>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline" className="w-full">
                        {field.value
                          ? filterData?.fazaPermbarimit[field.value as any]
                          : "Zgjidh fazën e përmbarimit"}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      align="center"
                      className="bg-secondary max-h-[300px] min-w-fit w-96 overflow-auto"
                    >
                      {filterData &&
                        Object.entries(filterData.fazaPermbarimit).map(
                          ([key, value]: any) => (
                            <DropdownMenuItem
                              key={key}
                              onClick={() => field.onChange(key)}
                            >
                              <Label className="font-semibold space-y-3 my-1 py-1 w-full border border-r-0 border-l-0 border-t-0 border-white">
                                {value}
                              </Label>
                            </DropdownMenuItem>
                          )
                        )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="statusVerbal"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Statusi Verbal</FormLabel>
                <FormControl>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline" className="w-full">
                        {field.value
                          ? filterData?.statusVerbal[field.value as any]
                          : "Zgjidh statusin verbal"}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      align="center"
                      className="bg-secondary max-h-[300px] min-w-fit w-96 overflow-auto"
                    >
                      {filterData &&
                        Object.entries(filterData.statusVerbal).map(
                          ([key, value]: any) => (
                            <DropdownMenuItem
                              key={key}
                              onClick={() => field.onChange(key)}
                            >
                              <Label className="font-semibold space-y-3 my-1 py-1 w-full border border-r-0 border-l-0 border-t-0 border-white">
                                {value}
                              </Label>
                            </DropdownMenuItem>
                          )
                        )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="dega"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Dega</FormLabel>
                <FormControl>
                  <Input placeholder="Dega" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex justify-center mt-7">
          <Button className="w-full md:w-1/2 sm:w-full" type="submit">
            {updateRastMutation.isPending ? (
              <Spinner fill="black" />
            ) : (
              "Ruaj Ndryshimet"
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default ModifikRastForm;
